<template>
    <div>
        <label >{{Item.name}}</label>
        <label v-if=(Item.hits)>{{Item.hits}}</label>
        <br>
        <div v-for="(chip, index) in Item.model" :key="index" class="chip">
                {{ chip }}
                <span class="closebtn"  @click="removeChip(index)">&times;</span>
        </div>
        <div class="row">
            <div class="column" style="width:90%">
                <md-field style="margin-top:0px;padding-top:5px">
                    <md-input type=text class="formInput" v-model=keyword @change="checkEdited" @input="checkAutocomplete($event)"/>
                </md-field>
            </div>
            <div class="column" style="width:5%">
                <md-button class="md-icon-button md-primary" style="width:30px;min-width: 30px" @click="addNewParingTag()"><md-icon>add</md-icon></md-button>
            </div>
        </div>

        <div class="autocompletediv" v-if="autocompleteList.length>0">
            <ul class="autocomplete-list">
                <li  v-for="autocompleteItem in autocompleteList" :key="autocompleteItem.Key">
                    <button  @click="setAutocompleteValue(autocompleteItem.Key, autocompleteItem.Name)">{{ autocompleteItem.Name }}</button>
                </li>
            </ul>
        </div>
        <Transition name="modal" v-if="showAddNewParingTag">
            <div  class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="popup">
                            <div class="modal-header">
                                <slot name="header">Add New Paring Tag</slot>
                            </div>
                            <div class="modal-body">
                                <LanguageObjectInput :Item="ParingTagNameInput"></LanguageObjectInput>
                                
                                <!-- <TextInput :Item="Type"></TextInput> -->
                                <md-field style="margin-top:0px;padding-top:0px">
                                    <md-select v-model=Type class="formInput">
                                        <md-option key="Food" value="Food" @change="checkEdited"> Food </md-option>
                                        <md-option key="Odor" value="Odor" @change="checkEdited"> Odor </md-option>
                                    </md-select>
                                </md-field>

                                <div class="row" style="text-align: center;padding-top: 20px;">
                                    <div class="column" style="width:47.5%">
                                        <button class="cancelButton" @click="popupcancel">Cancel</button>
                                    </div>
                                    <div class="column" style="width:5%"></div>
                                    <div class="column" style="width:47.5%">
                                        <button class="cancelButton" @click="createParingTag">Add</button>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </Transition>
        <md-snackbar :md-active.sync="showMessage">{{Message}}</md-snackbar>
    </div>
</template>
<script>
import LanguageObjectInput from "./LanguageObjectInput.vue";
//import TextInput from "./TextInput.vue";
export default {
    components: {
        LanguageObjectInput,
        //TextInput,
    },
    created() {
    },
    methods:{
        popupcancel: function(){
            this.showAddNewParingTag = false;
        },
        createParingTag: async function (){
            var object = {
                "Name": this.ParingTagNameInput.value,
                "Type": this.Type,
            }
            console.log("createParingTag", object);
            try{
                const ret= await this.$store.dispatch("createParingTag",{router:this.router, data:object})
                console.log("createParingTag", ret);
                this.showMessage = true;
                this.Message = "New Paring Tag Created";
                this.showAddNewParingTag = false;
                this.resetInputValue();
            }
            catch(err){
                console.log(err.message);
            }
            this.showAddNewParingTag = false;

        },
        addNewParingTag: function(){
            for(var i in this.languageList){
                this.ParingTagNameInput.value[this.languageList[i].Language] = ""
            }
            this.showAddNewParingTag = true;
            console.log("addNewCountry");
        },
        checkEdited:function(){
            this.$emit("checkEdited", true);
        },
        removeChip: function(index){
            this.Item.model.splice(index, 1);
            this.Item.value.splice(index, 1);
        },
        resetInputValue: function(){
            this.Type.value = "";
            this.Type.model = "";
            this.ParingTagNameInput.model = "";
            for(var i = 0; i< this.languageList.length; i++){
                this.ParingTagNameInput.value[this.languageList[i].Language] = ""
            }
        },
        searchFromServer: async function(keyword){
            var object = {
                "Keyword": keyword
            }
            try{
                const ret= await this.$store.dispatch("searchParingTag",{router:this.router, data:object})
                console.log("searchFromServer", ret);
                var resultList = ret.data.data.hits.hits
                this.autocompleteList = [];
                for(var i=0; i<resultList.length; i++){
                    var name = ""
                    for (const key in resultList[i]._source.Name) {
                        name += resultList[i]._source.Name[key]+"/";
                    }
                    name = name.slice(0, -1);
                    this.autocompleteList.push(
                        {
                            Key: resultList[i]._source.ParingTagID,
                            Name: name
                        }
                    )
                }

            }
            catch(err){
                console.log(err.message);
            }
        },
        checkAutocomplete: function(event){
            if(event.length > 1){
                console.log("do search")
                this.searchFromServer(event);      
            }else{
                this.autocompleteList = [];
                console.log("do not search")
            }
        },
        setAutocompleteValue: function(Key, Name){
            console.log("setAutocompleteValue", Key, Name)
            this.Item.model.push(Name)
            this.Item.value.push(Key)
            this.autocompleteList = [];  
            this.keyword = "";  
        },
    },
    name: "paringtag-input-component",
    props: {
        Item:{
            default() {
                return {};
            },
        },
        languageList:{
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            keyword: "",
            autocompleteList: [],
            showAddNewParingTag: false,
            showMessage: false,
            Message: "",
            ParingTagNameInput: {
                "type": "object",
                "key": "Name",
                "name": "Paring Tag Name",
                "required": true,
                "style": "width: 100%;",
                "model": "",
                "value": {
                }
            },
            Type: "",
        };
    },
    computed: {

    }
};
</script>
<style>
    .formInput{
        font-weight: 500;
        font-size: 18px !important;
    }
    .autocompletediv {
        background-color: white;
        z-index: 10;
        position: absolute;
    }
    .autocomplete-list {
        list-style: none;
        padding:unset;
        margin: unset;
        /* margin-top: -20px */
    }
    .autocomplete-list button{
        border: unset;
        background: unset;
        cursor: pointer;
    }
    .autocomplete-list li{
        border:1px solid #e9e9e9;
        border-top:unset;
    }
</style>