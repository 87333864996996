<template>
    <div class="content">
      
      <div class="md-layout">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
   
        <FormHeader
          :HeaderTitle="HeaderTitle"
          >
        </FormHeader>
        
        <div class="row">
          <div style="width:60%">
            <md-card style="margin:10px 0">
            <md-card-content>
              <InformationTable
                v-on:showMore="showMore"
                v-on:hideItem="hideItem"
                v-on:goPage="goPage"
                :tableHeader="firstTableInfo.Header"
                :ItemList="firstTableInfo.ItemList"
                :pageNumber="firstTableInfo.pageNumber"
                :showNextButton="firstTableInfo.showNextButton"
                :ShowMore="firstTableInfo.ShowMore"
              >
              </InformationTable>
            </md-card-content>
          </md-card>

          <md-card style="margin:10px 0">
            <md-card-content>
              <InformationTable
                v-on:showMore="showMore"
                v-on:hideItem="hideItem"
                v-on:goPage="goPage"
                :tableHeader="secondTableInfo.Header"
                :ItemList="secondTableInfo.ItemList"
                :pageNumber="secondTableInfo.pageNumber"
                :showNextButton="secondTableInfo.showNextButton"
                :ShowMore="secondTableInfo.ShowMore"
              >
              </InformationTable>
            </md-card-content>
          </md-card>

          <md-card style="margin:10px 0">
            <md-card-content>
              <InformationTable
                v-on:showMore="showMore"
                v-on:hideItem="hideItem"
                v-on:goPage="goPage"
                :tableHeader="thirdTableInfo.Header"
                :tableAction="thirdTableInfo.action"
                :ItemList="thirdTableInfo.ItemList"
                :ItemCenter="thirdTableInfo.ItemCenter"
                :pageNumber="thirdTableInfo.pageNumber"
                :showNextButton="thirdTableInfo.showNextButton"
                :ShowMore="thirdTableInfo.ShowMore"
                
              >
              </InformationTable>
            </md-card-content>
          </md-card>

          <md-card style="margin:10px 0">
            <md-card-content>
              <InformationTable
                v-on:showMore="showMore"
                v-on:hideItem="hideItem"
                v-on:goPage="goPage"
                :tableHeader="userActivityTableInfo.Header"
                :tableAction="userActivityTableInfo.action"
                :ItemList="userActivityTableInfo.ItemList"
                :ItemCenter="userActivityTableInfo.ItemCenter"
                :pageNumber="userActivityTableInfo.pageNumber"
                :showNextButton="userActivityTableInfo.showNextButton"
                :ShowMore="userActivityTableInfo.ShowMore"
                
              >
              </InformationTable>
            </md-card-content>
          </md-card>
          </div>
          <div style="width:40%;padding-left: 30px;">
            <md-card style="margin:10px 0">
            <md-card-content>
              <FormComponent
                v-on:editForm="editForm"
                :FormTitle="FormTitle"
                :FormItem="FormItem"
                :editAble="editAble"
                
              >
              </FormComponent>
            </md-card-content>
          </md-card>
          <button :class="checkOffical()" @click="setUserType('Offical')">Offical</button>
          <button :class="checkVerified()" @click="setUserType('Verified')" style="margin-left: 10px;">Verified</button>
          </div>
        </div>
        <hr>
      
        <!-- if userInfo.Enabled or userInfo.Enabled is null, show disable button  -->
        <button class="enableButton" v-if=(userInfo.Enabled) @click="showPopup=true">Disable account</button>
        <button class="enableButton" v-else @click="actionForAccount('enable')">Enable account</button>
        
        </div>
        <Transition name="modal" v-if="showPopup">
          <Popup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="popupaction"
            
            :PopupTitle="PopupTitle"
            :PopupContent="PopupContent"
            :actionButton="actionButton"
          ></Popup>
        </Transition>
      </div>
      <Spinner v-if="isLoading"/> 
      <md-snackbar :md-active.sync="showMessage">{{message}}</md-snackbar>
    </div>
  </template>
  <script>


import {
  FormHeader,
  InformationTable,
  FormComponent,
  Popup,
  Spinner
  } from "@/components";

  export default {
    components: {
      FormHeader,
      InformationTable,
      FormComponent,
      Popup,
      Spinner
    },
    async created() {  
      this.isLoading = true;
      this.message = this.$route.params.message;
      this.checkShowMessage();
      this.getCustomerOrder(this.$route.params.customer)
      this.getCustomerGathering(this.$route.params.customer);
      this.getCustomerUserGroup(this.$route.params.customer)
      this.getUserActivity(this.$route.params.customer);
      await this.getUser(this.$route.params.customer);
      this.isLoading = false;
    },
    methods:{
      setUserType: async function(UserType){
        if(UserType == "Offical"){
          this.Offical = !this.Offical
        }else if(UserType == "Verified"){
          this.Verified = !this.Verified
        }
        var object = {
          UserID: this.userInfo.UserID,
          Offical: this.Offical,
          Verified: this.Verified
        }
        this.isLoading = true;
        try{
          const ret= await this.$store.dispatch('updateUserType',{router:this.router,customer:object})
          this.isLoading = false;
          this.message = this.userInfo.Name + "'s "+ UserType + " has been updated"
          this.showMessage = true
        }catch(err){
          console.log("updateUserType error", err.response.data);
          this.isLoading = false;
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
      },
      checkOffical: function(){
        if(this.Offical){
          return "disableButton"
        }else{
          return "enableButton"
        }
      },
      checkVerified: function(){
        if(this.Verified){
          return "disableButton"
        }else{
          return "enableButton"
        }
      },
      goPage:function(object){
        console.log("goPage ", object)
        if(object.table == this.firstTableInfo.Header){
          if(object.action == "next"){
            this.firstTableInfo.index = this.firstTableInfo.index + this.firstTableInfo.displaySize
            this.firstTableInfo.pageNumber = this.firstTableInfo.pageNumber + 1
          }else if(object.action == "prev" && this.firstTableInfo.index > 0){
            this.firstTableInfo.index = this.firstTableInfo.index - this.firstTableInfo.displaySize
            this.firstTableInfo.pageNumber = this.firstTableInfo.pageNumber - 1
          }else{
            this.firstTableInfo.index = 0
            this.firstTableInfo.pageNumber = 1
          }
            this.getCustomerOrder(this.userInfo.UserID)
        }else if (table == this.secondTableInfo.Header){
          if(object.action == "next"){
            this.secondTableInfo.index = this.secondTableInfo.index + this.secondTableInfo.displaySize
            this.secondTableInfo.pageNumber = this.secondTableInfo.pageNumber + 1
          }else if(object.action == "prev" && this.secondTableInfo.index > 0){
            this.secondTableInfo.index = this.secondTableInfo.index - this.secondTableInfo.displaySize
            this.secondTableInfo.pageNumber = this.secondTableInfo.pageNumber - 1
          }else{
            this.secondTableInfo.index = 0
            this.secondTableInfo.pageNumber = 1
          }
            this.getCustomerGathering(this.userInfo.UserID)
        }
      },
      showMore:function (table){
        console.log("here showMore")
        if(table == this.firstTableInfo.Header){
          this.firstTableInfo.displaySize = 5;
          this.firstTableInfo.ShowMore = true;
          this.getCustomerOrder(this.userInfo.UserID)
        }else if (table == this.secondTableInfo.Header){
          this.secondTableInfo.displaySize = 5;
          this.secondTableInfo.ShowMore = true;
          this.getCustomerGathering(this.userInfo.UserID)
        }
      },
      hideItem: function (table){
        if(table == this.firstTableInfo.Header){
          this.firstTableInfo.displaySize = 2;
          this.firstTableInfo.index = 0
          this.firstTableInfo.pageNumber = 1
          this.firstTableInfo.ShowMore = false;
          this.getCustomerOrder(this.userInfo.UserID)
        }else if (table == this.secondTableInfo.Header){
          this.secondTableInfo.displaySize = 2;
          this.secondTableInfo.index = 0
          this.secondTableInfo.pageNumber = 1
          this.secondTableInfo.ShowMore = false;
          this.getCustomerGathering(this.userInfo.UserID)
        }
      },
      popupcancel: function(){
        this.showPopup = false;
      },
      popupaction: function (){
        this.showPopup = false;
        this.actionForAccount("disable");
      },  
      checkShowMessage: async function(){
      if(this.message){
        try{
          await this.delayFunction();
          this.showMessage = true;
        }catch(err){
          console.log(err)
        }
      }
    },
    delayFunction:async function (){
        setTimeout(function(){
          this.showMessage = false;
          this.message = "";
          return true;
        }, 1000);
    },
      editForm: function(){
        this.$router.push({name: 'Update Customer', params:{customer:this.userInfo.UserID}})
      },
      getUser: async function(UserID){
      try{
          const ret= await this.$store.dispatch('getCustomerInfo',{router:this.router,customer:{userID: UserID}})
          console.log("getCustomerInfo", ret.data.userInfo);
          this.Offical = ret.data.userInfo.Offical
          this.Verified = ret.data.userInfo.Verified
          this.userInfo = ret.data.userInfo  
          if(ret.data.userInfo.Enabled == null){
            this.userInfo.Enabled = true
          }
          this.HeaderTitle = this.userInfo.Name
          this.setDatatoForm(this.userInfo)
        }catch(err){
          console.log("getCustomerInfo error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
      },
      setDatatoForm: function(data){
        console.log("setDatatoForm", data)
        for (const [key, value] of Object.entries(this.FormItem)) {
          console.log(value)
          for(var i = 0 ;i<value.length; i++){
            if(value[i].type == "phone"){
              value[i]["value"] = data[value[i].key];
              value[i]["countyCode"] = data["PhoneCountryCode"];
            }else{
              value[i]["value"] = data[value[i].key];
            }
          }
        }
        console.log("FormItem", this.FormItem)
      },
      getCustomerOrder: async function(UserID){
        var object = {
          UserID: UserID,
          Size: this.firstTableInfo.displaySize,
          From: this.firstTableInfo.index,
          Source: ["CreatedDatetime", "OrderID", "Total"]
        }
        try{
          const ret= await this.$store.dispatch('getCustomerOrder',{router:this.router,customer:object})
          this.setUserOrderList(ret.data.data.hits.hits)
        }catch(err){
          console.log("getCustomerOrder error", err);
          this.formMessage = err
          this.showFormMessage = true
        }
      },
      setUserOrderList: function(list){
        var newlist=[];
        for(var i = 0; i<list.length; i++){
          //timestamp to date
          
          var date = new Date(list[i]["_source"].CreatedDatetime).toDateString();
          var object = {
            "ID": list[i]["_source"].OrderID,
            "CreatedDatetime": "Create at: "+date,
            "Total": "$"+list[i]["_source"].Total,
          }
          newlist.push(object)
        }
        this.firstTableInfo.ItemList = newlist;
        if(newlist.length < this.firstTableInfo.displaySize){
            this.firstTableInfo.showNextButton = false
        }else{
          this.firstTableInfo.showNextButton = true
        }
      },
      getCustomerGathering: async function(UserID){
        // var object = {
        //   UserID: UserID,
        //   Size: this.secondTableInfo.displaySize,
        //   From: this.secondTableInfo.index
        // }
        // try{
        //   const ret= await this.$store.dispatch('getCustomerGathering',{router:this.router,customer:object})
        //   this.setUserGatheringList(ret.data.data.hits.hits)
        // }catch(err){
        //   console.log("getCustomerGathering error", err.response.data);
        //   this.formMessage = err.response.data.message
        //   this.showFormMessage = true
        // }
      },
      setUserGatheringList: function(list){
        var newlist=[];
        for(var i = 0; i<list.length; i++){
          var object = {
            "ID": list[i]["_source"].GatheringID,
            "Gathering": list[i]["_source"].gatheringTitle
          }
          newlist.push(object)
        }
        this.secondTableInfo.ItemList = newlist;
        if(newlist.length < this.secondTableInfo.displaySize){
            this.secondTableInfo.showNextButton = false
        }else{
          this.secondTableInfo.showNextButton = true
        }
      },
      getCustomerUserGroup: async function(UserID){
        try{
          const ret= await this.$store.dispatch('getCustomerUserGroup',{router:this.router,customer:{UserID: UserID}})
          this.setUserUserGroupList(ret.data.UserGroup)
        }catch(err){
          console.log("getCustomerUserGroup error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
      },
      setUserUserGroupList: function(data){
        var list = data.Items
        var newlist=[];
        for(var i = 0; i<list.length; i++){
          var object = {
            "ID": list[i].UserGroupID,
          }
          newlist.push(object)
        }
        this.thirdTableInfo.ItemList = newlist;
      },
      getUserActivity: async function(UserID){
        var object = {
          "Size": 2,
          "From": 0,
          "FilterOut": [{"match":{"UserID": UserID}}],
          "Sort": [{"CreatedDatetime":{"order":"desc"}}]
        }
        try{
          const ret= await this.$store.dispatch('searchUserActivity',{router:this.router,keyword:object})
          this.setUserActivityList(ret.data.data.hits.hits)
        }catch(err){
          console.log("getUserActivity error", err);
          this.formMessage = err.response.message
          this.showFormMessage = true
        }
      },
      setUserActivityList: function(data){
        var list = data
        var newlist=[];
        for(var i = 0; i<list.length; i++){
          var object = {
            "Action": list[i]._source.Action,
            "Datetime": new Date(list[i]._source.CreatedDatetime).toISOString()
          }
          newlist.push(object)
        }
        this.userActivityTableInfo.ItemList = newlist;
      },
      actionForAccount: async function (action){

        try{
          const ret= await this.$store.dispatch('enabledisableCustomer',{router:this.router,data:{userID: this.userInfo.UserID, action: action}})
          this.message = this.userInfo.Name + " has been "+ action
          this.$router.push({path: '/customer/1', params:{message: this.message}})
        }catch(err){
          console.log("enabledisableCustomer error", err.response.data);
          this.formMessage = err.response.data.message
          this.showFormMessage = true
        }
      }
      
    },
    
    data() {
      return {
        isLoading: false,
        showMessage: false,
        message: "",
        HeaderTitle: "",
        Offical: false,
        Verified: false,
        firstTableInfo: {
          Header: "Order Record",
          ItemList: [],
          index: 0,
          pageNumber: 1,
          displaySize: 2,
          showNextButton: true,
          ShowMore: false,
        },
        secondTableInfo: {
          Header: "Gathering Record",
          ItemList: [],
          index: 0,
          pageNumber: 1,
          displaySize: 2,
          showNextButton: true,
          ShowMore: false,
        },
        thirdTableInfo: {
          Header: "Customer Group",
          ItemList: [],
          index: 0,
          pageNumber: 1,
          displaySize: 2,
          showNextButton: true,
          ShowMore: false,
          action: "Add to a Group",
          ItemCenter: false
        },
        userActivityTableInfo: {
          Header: "User Activity",
          ItemList: [],
          index: 0,
          pageNumber: 1,
          displaySize: 1,
          showNextButton: true,
          ShowMore: false,
          ItemCenter: false
        },
        FormTitle: "Customer Profile",
        editAble: false,
        FormItem: {
        "row1":[
          {
            "type": "text",
            "key": "GivenName",
            "name": "First Name",
            "required": true,
            "value": ""
          },
          {
            "type": "text",
            "key": "FamilyName",
            "name": "Last Name",
            "required": true,
            "value": ""
          },
        ],
        "row2":[
          {
            "type": "text",
            "key": "Name",
            "name": "Username",
            "required": true,
            "value": ""
          },
        ],
        "row3":[
          {
            "type": "phone",
            "key": "Phone",
            "name": "Phone",
            "required": true,
            "value": "",
            "countyCode": ""
          },
          {
            "type": "text",
            "key": "Email",
            "name": "Email",
            "required": true,
            "value": ""
          },
        ],
        "row4":[
          {
            "type": "date",
            "key": "Birthday",
            "name": "Birthday",
            "required": true,
            "value": ""
          },
          {
            "type": "select",
            "key": "Gender",
            "name": "Gender",
            "required": true,
            "value": "",
            "option": [
              {
                "key": "M",
                "name": "M",
              },
              {
                "key": "F",
                "name": "F",
              },
              {
                "key": "Others",
                "name": "Others",
              }
            ]
          },
        ],
      },
        userInfo: {},
        
        userGatheringList: [],
        userUserGroupList: [],
        showPopup: false,
        PopupTitle: "Disable account",
        PopupContent: "This action will forbid customers to access their account",
        actionButton: "Disable anyway",
      };
    },

  };
  </script>
<style>
  .enableButton{
    color: #cc2727;
    border-color: #cc2727;
    border-width: thin;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: 400;
  }
  .disableButton{
    color: #cc2727;
    border-color: #cc2727;
    border-width: thin;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: 400;
    background-color: #cc2727;
    color: white;
  }
</style>