<template>
    <div>
        <label >{{Item.name}}</label>
        <label v-if=(Item.hits)>{{Item.hits}}</label>
        <div class="row">
            <div class="column" style="width:90%">
                <md-field style="margin-top:0px;padding-top:5px">
                    <md-input type=text class="formInput" v-model=Item.model @change="checkEdited" @input="checkAutocomplete($event, Item)"/>
                </md-field>
            </div>
            <div class="column" style="width:5%">
                <md-button class="md-icon-button md-primary" style="width:30px;min-width: 30px" @click="addNewCountry()"><md-icon>add</md-icon></md-button>
            </div>
            
        </div>

        <div class="autocompletediv" v-if="autocompleteList.length>0">
            <ul class="autocomplete-list">
                <li  v-for="autocompleteItem in autocompleteList" :key="autocompleteItem.Key">
                    <button  @click="setAutocompleteValue(autocompleteItem.Key, autocompleteItem.Name)">{{ autocompleteItem.Name }}</button>
                </li>
            </ul>
        </div>
        <Transition name="modal" v-if="showAddNewCountry">
            <div  class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container" style="height: 75%;">
                        <div class="popup">
                            <div class="modal-header">
                                <slot name="header">Add New Country</slot>
                            </div>
                            <div class="modal-body">
                                <LanguageObjectInput :Item="CountryNameInput"></LanguageObjectInput>
                                <TextInput :Item="Alpha2Input"></TextInput>
                                <TextInput :Item="Alpha3Input"></TextInput>
                                <TextInput :Item="CountryCodeInput"></TextInput>
                                <TextInput :Item="NumericInput"></TextInput>
                                <div class="row" style="text-align: center;padding-top: 20px;">
                                    <div class="column" style="width:47.5%">
                                        <button class="cancelButton" @click="popupcancel">Cancel</button>
                                    </div>
                                    <div class="column" style="width:5%"></div>
                                    <div class="column" style="width:47.5%">
                                        <button class="cancelButton" @click="createCountry">Add</button>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </Transition>
        <md-snackbar :md-active.sync="showMessage">{{Message}}</md-snackbar>
    </div>

</template>
<script>
import LanguageObjectInput from "./LanguageObjectInput.vue";
import TextInput from "./TextInput.vue";
export default {
    components: {
        LanguageObjectInput,
        TextInput,
    },
    created() {
    },
    methods:{
        popupcancel: function(){
            this.showAddNewCountry = false;
        },
        createCountry: async function (){
            var object = {
                "Name": this.CountryNameInput.value,
                "Alpha2": this.Alpha2Input.value,
                "Alpha3": this.Alpha3Input.value,
                "CountryCode": this.CountryCodeInput.value,
                "Numeric": this.NumericInput.value,
            }
            console.log("createCountry", object);
            try{
                const ret= await this.$store.dispatch("createCountry",{router:this.router, data:object})
                console.log("createCountry", ret);
                this.showMessage = true;
                this.Message = "Country Created";
                this.showAddNewCountry = false;
                this.resetInputValue();
            }
            catch(err){
                console.log(err.message);
            }
            this.showAddNewCountry = false;

        },
        addNewCountry: function(){
            console.log("setLanguage", this.languageList);
            for(var i in this.languageList){
                this.CountryNameInput.value[this.languageList[i].Language] = ""
            }
            this.showAddNewCountry = true;
            console.log("addNewCountry");
        },
        checkEdited:function(){
            this.$emit("checkEdited", true);
        },
        removeChip: function(index){
            this.Item.model.splice(index, 1);
            this.Item.value.splice(index, 1);
        },
        resetInputValue: function(){
            this.Alpha2Input.value = "";
            this.Alpha3Input.value = "";
            this.CountryCodeInput.value = "";
            this.NumericInput.value = "";
            this.Alpha2Input.model = "";
            this.Alpha3Input.model = "";
            this.CountryCodeInput.model = "";
            this.NumericInput.model = "";

            this.CountryNameInput.model = "";
            for(var i = 0; i< this.languageList.length; i++){
                this.CountryNameInput.value[this.languageList[i].Language] = ""
            }
        },
        searchFromServer: async function(keyword){
            var object = {
                "Keyword": keyword
            }
            try{
                const ret= await this.$store.dispatch("searchCountry",{router:this.router, data:object})
                console.log("searchFromServer", ret);
                var resultList = ret.data.data.hits.hits
                this.autocompleteList = [];
                for(var i=0; i<resultList.length; i++){
                    this.autocompleteList.push(
                        {
                            Key: resultList[i]._source.CountryID,
                            Name: resultList[i]._source.Name.en+", "+resultList[i]._source.Name.zh+", "+resultList[i]._source.Alpha2+", "+resultList[i]._source.Alpha3,      
                        }
                    )
                }

            }
            catch(err){
                console.log(err.message);
            }
        },
        checkAutocomplete: function(event){
            if(event.length > 1){
                this.searchFromServer(event);      
            }else{
                this.autocompleteList = [];
            }
        },
        setAutocompleteValue: function(Key, Name){
            this.Item.model = Name;
            this.Item.value = Key
            this.autocompleteList = [];  
            this.keyword = "";  
        },
    },
    name: "country-input-component",
    props: {
        Item:{
            default() {
                return {};
            },
        },
        languageList:{
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            keyword: "",
            autocompleteList: [],
            showAddNewCountry: false,
            showMessage: false,
            Message: "",
            CountryNameInput: {
                "type": "object",
                "key": "Name",
                "name": "Country Name",
                "required": true,
                "style": "width: 100%;",
                "model": "",
                "value": {
                }
            },
            Alpha2Input: {
                "type": "text",
                "key": "Alpha2",
                "name": "Alpha2",
                "required": true,
                "style": "width: 100%;",
                "value": ""
            },
            Alpha3Input: {
                "type": "text",
                "key": "Alpha3",
                "name": "Alpha3",
                "required": true,
                "style": "width: 100%;",
                "value": ""
            },
            CountryCodeInput: {
                "type": "text",
                "key": "CountryCode",
                "name": "Country Code",
                "required": true,
                "style": "width: 100%;",
                "value": ""
            },
            NumericInput: {
                "type": "text",
                "key": "Numeric",
                "name": "Numeric",
                "required": true,
                "style": "width: 100%;",
                "value": ""
            },

        };
    },
    computed: {

    }
};
</script>
<style>
    .formInput{
        font-weight: 500;
        font-size: 18px !important;
    }
    .autocompletediv {
      z-index: 10;
      position: absolute;
    }
    .autocomplete-list {
        list-style: none;
        padding:unset;
        margin: unset;
        /* margin-top: -20px */
    }
    .autocomplete-list button{
        border: unset;
        background: unset;
        cursor: pointer;
    }
    .autocomplete-list li{
        border:1px solid #e9e9e9;
        border-top:unset;
    }
    .add-button{
        border: unset;
        background: unset;
        cursor: pointer;
        margin-top: 10px;
    }
</style>