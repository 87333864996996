<template>
    <div>
        <md-card class="TagePage" style="margin: unset;">
          <md-card-content>
            <div v-if="(ProductList.length>0)" >
                <div style="width: 100%;text-align: right;">
                    <button @click="addProduct()" class="newProductButton">Add Product</button>
                </div>
                <draggable
                      :list="ProductList"
                      :group="{ name: 'ProductList' }"
                      style="height: 65vh; overflow: auto;margin-top: 15px;"
                    >
                    <div v-for="(product) in ProductList" :key="product.ID">
                        <div class="collapsible">
                            <input type="checkbox" style="display:none" :id=product.ID checked/>
                            <div class="row unsetMargin" @click="expandItem(product.ID)">
                                <div class="explorIcon" > 
                                    <md-icon :id="product.ID+icon" style="color: white;">expand_less</md-icon>
                                </div>
                                <div class="col explorTitle"><label>{{product.ProductName}}</label></div>
                            </div>
                            <div class="collapsible-item" :id="product.ID+content" style="min-height: 250px">
                                <!-- {{ product }} -->
                                <div class="row">
                                    <div class="column" style="padding:20px">
                                        <div>
                                            <img class="displayImage" :src=product.img>
                                        </div>
                                        <div>
                                            <a class="remove" @click="removeProduct(product.ID)">remove</a>
                                        </div>
                                        
                                    </div>
                                    <div class="col displayItem" style="padding:20px">
                                        <div v-if="(product.Survey.length > 0)">
                                            <div  v-for="(survey, key) in product.Survey" :key="key">
                                                <div class="parameter row">
                                                    <div style="width: 85%;">
                                                        <label style="padding-left: 10px;">Parameter {{ key+1 }} : </label>
                                                        <label v-for="(parameter, keyParameter) in survey.Parameter" :key="keyParameter">
                                                            {{ parameter }}
                                                        </label>
                                                       
                                                        <label v-if="(survey.SurveyName)" style="padding-left: 50px;">問卷 : </label>
                                                        <label v-if="(survey.SurveyName)" >{{ survey.SurveyName }}</label>
                                                        
                                                    </div>
                                                    <div style="text-align: right; width: 15%;">
                                                        <label class="remove" style="padding-right: 10px;" @click="deleteParameter(product.ID, key)">delete</label>
                                                    </div>

                                                   
                                                </div>

                                            </div>
                                        </div>

                                        <a class="addParamter" @click="addParameter(product.ID)"><u>+ add parameter</u></a>

                                    </div>
                                </div>
                                
                            </div> 
                        </div>
                    </div>
                </draggable>
            </div>
            <div v-else>
                <button @click="addProduct()" class="newProductButton centerButton">Add Product</button>
            </div>
          </md-card-content>
        </md-card>
        <Transition name="modal" v-if="showSelectProduct">
            <div  class="modal-mask">
                <div class="modal-wrapper">
                    <div class="product-select-container">
                        <div class="popup">
                            <div class="row" style="padding: 0 10px">
                                <md-button class="backbutton" style="background-color:white !important;" @click="showSelectProduct=false">
                                    <md-icon class="backIcon" style="color:black !important;margin-left: -10px;">arrow_back_ios</md-icon>
                                </md-button>     
                            </div>
                            <md-card>
                            <md-card-content >
                                <TableComponent 
                                v-on:selectItem="selectItem"
                                v-on:selectAllItem="selectAllItem"
                                v-on:selectPageItem="selectPageItem"
                                v-on:searchItem="searchItem"
                                v-on:selectSortOption="selectSortOption"
                                v-on:onclickItem="onclickItem"
                                v-on:selectedItemActionFunction="selectedItemActionFunction"
                                v-on:goPage="goPage"
                                v-on:SortPopupControl="SortPopupControl"
                                v-on:setFilter="setFilter"
                                :TableList="TableItemList"
                                :selectedItemCounter="selectedItemCounter"
                                :selectedItem="selectedItem"
                                :columns="columns"
                                :selectAll="selectAll"
                                :selectable="selectable"
                                :haveSearchFunction="haveSearchFunction"
                                :sortOption="sortOption"
                                :selectedItemAction="selectedItemAction"
                                :pageNumber="pageNumber"
                                :showNextButton="showNextButton"
                                :showSortPopup="showSortPopup"
                                :filterOption="filterOption"
                                />
                            </md-card-content>
                            </md-card>
                        </div> 
                    </div>
                </div>
            </div>
        </Transition>
        <Transition name="modal" v-if="showAddParamter">
            <div  class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="popup">
                            <div class="modal-header">
                                <slot name="header">Parameter from Host</slot>
                            </div>
                            <div class="modal-body">
                                <div class="row" >
                                    <div class="column inputcolumn" v-for="(field, key) in newAddParameterList" :key="key">
                                        <TextInput
                                            v-if="field.type=='Text'"
                                            v-on:checkEdited="checkEdited"
                                            :Item="field"
                                        /> 
                                        <SelectInput
                                            v-if="field.type=='Option'"
                                            v-on:checkEdited="checkEdited"
                                            :Item="field"
                                            style="padding-top:25px"
                                        /> 
                                        <TimeInput
                                            v-if="field.type=='Time'"
                                            v-on:checkEdited="checkEdited"
                                            :item="field"
                                            :name="field.name"
                                            :value="field.value"
                                        />

                                    </div>
                                    <div class="column inputcolumn">
                                        <md-field style="margin-top:0px;padding-top:40px">
                                            <md-select v-model=SurveyTemplateInput.value class="formInput" placeholder="Product Survey">
                                                <md-option v-for="option in SurveyTemplateInput.option" :key="option.key" :value=option.key>{{option.name}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>

                                </div>
                                <div class="row" style="text-align: center;padding-top: 30px;">
                                    <div class="column" style="width:47.5%">
                                        <label @click="showAddParamter=false">Cancel</label>
                                    </div>
                                    <div class="column" style="width:5%"></div>
                                    <div class="column" style="width:47.5%">
                                        <button class="cancelButton" @click="addParameterToProduct">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </Transition>
        <Transition name="modal" v-if="showDeleteParameterPopup">
          <Popup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="DeleteParameterAction"   
            :PopupTitle="DeleteParameterTitle"
            :PopupContent="DeleteParameterContent"
            :actionButton="actionButton"
          ></Popup>
        </Transition>
        <Transition name="modal" v-if="showDeleteProductPopup">
          <Popup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="DeleteProductAction"   
            :PopupTitle="DeleteProductTitle"
            :PopupContent="DeleteProductContent"
            :actionButton="actionButton"
          ></Popup>
        </Transition>
        <md-snackbar :md-active.sync="showFormMessage">{{formMessage}}</md-snackbar>
        <Spinner v-if="isLoading"/> 
    </div>
</template>

<script>

import { 
  TableComponent,
  Spinner,
  SelectInput,
  TextInput,
  TimeInput,
  Popup,
} from "@/components";


import draggable from "vuedraggable";
export default {
    components: {
        TableComponent,
        Spinner,
        SelectInput,
        TextInput,
        TimeInput,
        Popup,
        draggable
    },
    async created() {
        this.isLoading = true;
        this.setFilterOut();
        await this.getEventProductSurvey();
        await this.getProductType();
        this.isLoading = false;
        // var height = window.innerHeight;
        // console.log("height", height);
        // if(height < 830){
        //    this.displaySize = Math.ceil(height/52/2);
        // }
    },
    methods:{
        /*
            Popup Function
        */
        popupcancel: function(){
            this.showDeleteParameterPopup = false;
            this.showDeleteProductPopup = false;
        },
        async addEventProduct(object){
            this.isLoading = true;
            try{
                const ret = await this.$store.dispatch("addEventProduct",{router:this.router, data:object})
                console.log("addEventProduct", ret.data);
                this.$emit("getSurvey", this.$route.params.eventID);
                this.isLoading = false;
                this.formMessage = "Add Parameter Success"
                this.showFormMessage = true
                this.$forceUpdate();
            }catch(error){
                this.isLoading = false;
                console.log(error);
            }
        },
        DeleteProductAction: async function(){
            var object = {
                EventID: this.$route.params.eventID,
                ProductID: this.delProductID  
            }
            this.isLoading = true;
            try{
                const ret= await this.$store.dispatch("deleteEventProduct",{router:this.router, data:object})
                var index = this.ProductList.findIndex(product => product.ID == this.delProductID);
                this.ProductList.splice(index, 1);
                this.setFilterOut();
                this.showDeleteProductPopup = false;
                this.isLoading = false;
                this.formMessage = "Delete Product Success"
                this.showFormMessage = true
            }catch(error){
                this.isLoading = false;
                console.log(error);
            }

            //this.showDeleteParameterPopup = false;   
        },
        DeleteParameterAction: async function(){
            var object = {
                EventID: this.$route.params.eventID,
                SurveyID: this.delSurveyID
            }
            this.isLoading = true;
            try{
                const ret= await this.$store.dispatch("deleteEventSurvey",{router:this.router, data:object})
                var index = this.ProductList.findIndex(product => product.ID == this.delParameterProductID);
                var surveyIndex = this.ProductList[index]["Survey"].findIndex(survey => survey.Survey == this.delSurveyID);
                this.ProductList[index]["Survey"].splice(surveyIndex, 1);
                this.showDeleteParameterPopup = false;
                this.isLoading = false;
                this.formMessage = "Delete Parameter Success"
                this.showFormMessage = true
            }catch(error){
                this.isLoading = false;
                console.log(error);
            }

            //this.showDeleteParameterPopup = false;   
        },
        deleteParameter: function (ProductID, surveyIndex){
            console.log("deleteParameter", ProductID, surveyIndex);
            var index = this.ProductList.findIndex(product => product.ID == ProductID);
            console.log(this.ProductList[index]["Survey"][surveyIndex]["new"]);
            if(this.ProductList[index]["Survey"][surveyIndex]["new"] == true){
                this.ProductList[index]["Survey"].splice(surveyIndex, 1);
                return
            }else if(this.Status == "Draft"){
                this.delSurveyID = this.ProductList[index]["Survey"][surveyIndex]["Survey"];
                this.delParameterProductID = ProductID;
                this.showDeleteParameterPopup = true;

                
            }else{
                this.formMessage = "You can't delete parameter in this status"
                this.showFormMessage = true
            }
        },
        addParameterToProduct: async function (){
            console.log("addParameterToProduct", this.newAddParameterList);
            console.log(this.ProductList);
            var ParameterValue = {}
            for(var i = 0; i<this.newAddParameterList.length; i++){
                if(this.newAddParameterList[i].value == "" || this.newAddParameterList[i].value == null) {
                    this.formMessage = this.newAddParameterList[i].name + " is required"
                    this.showFormMessage = true
                    return
                }
                var valueString = this.newAddParameterList[i].value.toString()
                if(this.newAddParameterList[i].type == "Text" && this.newAddParameterList[i].unit){
                    valueString = valueString + this.newAddParameterList[i].unit
                    
                }
                ParameterValue[this.newAddParameterList[i].key] = valueString
            }
           
            var index = this.ProductList.findIndex(product => product.ID == this.newAddParameterID);
            var survey = this.SurveyTemplateInput.option.find(survey => survey.key == this.SurveyTemplateInput.value);

            if(this.mode == "Create"){
                this.ProductList[index]["Survey"].push({
                    Parameter: ParameterValue,
                    Survey: this.SurveyTemplateInput.value,
                    SurveyName: survey.name,
                    new: true
                });
            }else{
                var object = {
                    EventID: this.$route.params.eventID,
                    ProductID: this.ProductList[index].ID,
                    SurveyID: this.SurveyTemplateInput.value,
                    Parameter: ParameterValue
                }
                await this.addEventProduct(object);
            }

            console.log(this.ProductList);
            this.SurveyTemplateInput.value = null;
            this.showAddParamter = false;
        },
        addParameter: function(ID){
            console.log("addParameter", ID);
            this.newAddParameterID = ID;
            var product = this.ProductList.find(product => product.ID == ID);
            console.log(product.Parameter)
            if(!product.Parameter){
                return
            }
            this.newAddParameterList = [];
            for(var p in product.Parameter){
               var parameter = product.Parameter[p]
               var object = {
                    type: parameter.Type,
                    required: true,
                    key: parameter.Key,
                    name: parameter.Name.zh,
                    value: ""
                }
                if(parameter.Type == 'Option'){
                    var option = [];
                    for(var o in parameter.Option){
                        var optionSelect = parameter.Option[o]
                        option.push({
                            key: optionSelect.Key,
                            name: optionSelect.Name.zh,
                        })
                        object.option = option;
                    }
                }
                if(parameter.Type == 'Text'){
                    if(parameter.Unit){
                        object.unit = parameter.Unit
                    }
                }
                this.newAddParameterList.push(object)
            }
            this.showAddParamter = true;
        },
        async getEventProductSurvey(){
            var object={
                TemplateType: "EventProduct"
            }
            try{
                const ret= await this.$store.dispatch("listSurveyTemplates",{router:this.router, data:object})
                console.log("listSurveyTemplates", ret.data);
                for(var i = 0; i< ret.data.data.length; i++){
                    this.SurveyTemplateInput.option.push({
                        "key": ret.data.data[i].TemplateID,
                        "name": ret.data.data[i].Name +" ("+ret.data.data[i].Description+")",
                    })
                }
            }
            catch(err){
                console.log(err.message);
            }
        },
        getProductType: async function(){
            try{
                const ret= await this.$store.dispatch("getProductType",{router:this.router, data:null})
                this.ProductTypeList = ret.data.ProductType;
                console.log("getProductType", this.ProductTypeList)
                var filter = this.filterOption.find(x => x.Key == "ProductType")
                filter.option = [];
                for(var p in this.ProductTypeList){
                filter.option.push({
                    "Key": this.ProductTypeList[p].ProductTypeID,
                    "Name": this.ProductTypeList[p].Name.zh,
                    "Value": false
                })

                }
            }
            catch(err){
                console.log("getProductType error", err.response.data);
                this.formMessage = err.response.data.message
                this.showFormMessage = true
            }
        },
        removeProduct: function(ID){
            console.log("filterout", this.filterOut);
            if(this.mode == "Create"){
                var index = this.ProductList.findIndex(product => product.ID == ID);
                this.ProductList.splice(index, 1);
                this.setFilterOut();
                this.$emit("setProductList", this.ProductList);
                return
            }else{
                this.delProductID = ID;
                this.showDeleteProductPopup = true;
            }
        },
        checkEdited:function(event){
          console.log(event);
          this.$emit("checkEdited", true);
        },
        addProduct: async function(){ 
            console.log("add product");
            this.isLoading = true;
            await this.searchItem({keyword:""});
            this.isLoading = false;
            this.showSelectProduct = true;  

        },
        getProduct: async function(ProductList){
            try{
                const ret= await this.$store.dispatch("getProduct",{router:this.router, product:{ProductList:ProductList}})
                return ret.data.ProductDetailList;
            }
            catch(err){
                console.log(err.message);
            }
        },
        /*
            Product Table Function
        */
        selectItem:function(ID, action){
        console.log(ID, action);
            if(action){
                this.selectedItem.push(ID)
            }else{
                this.selectAll = false;
                var index = this.selectedItem.indexOf(ID)
                this.selectedItem.splice(index, 1);
            }
            console.log(this.selectedItem.length);
            this.selectedItemCounter = this.selectedItem.length
        },
        selectPageItem:async function(action){
            console.log("selectPageItem", action)
            console.log(this.TableItemList)   
            if(action){
                for(var i in this.TableItemList){
                var index = this.selectedItem.indexOf(this.TableItemList[i].ID)
                if(index == -1){
                    this.selectedItem.push(this.TableItemList[i].ID)
                }
                }
            }else{
                for(var i in this.TableItemList){
                var index = this.selectedItem.indexOf(this.TableItemList[i].ID)
                if(index > -1){
                    this.selectedItem.splice(index, 1)
                }
                }
            }
            this.selectedItemCounter = this.selectedItem.length
        },
        selectAllItem: async function(action, {keyword}){
            console.log("action", action);
            console.log("keyword", keyword);
                if(!action){
                    this.selectAll = true;
                    this.isLoading = true;
                    var object = {
                        "Source": this.Source,
                        "Filter": this.filter,
                        "Range": this.range
                    }
                    if(keyword){
                        object["Keyword"] = keyword
                    }
                    if(this.filterOut.length > 0){
                        object["FilterOut"] = this.filterOut
                    }
                    try{
                        const ret= await this.$store.dispatch('searchProduct',{router:this.router,keyword:object})
                        this.selectedItem = ret.data.data.hits.hits.map(item => String(item._id))
                        this.isLoading = false;
                        }catch(err){
                        
                        this.formMessage = err.response.data.message
                        this.showFormMessage = true
                        } 
                    }else{
                        this.selectAll = false;
                        this.selectedItem = []
                    }
                console.log("after", this.selectedItem);
                this.selectedItemCounter = this.selectedItem.length
        },
        setFilterOut: function(){
            this.filterOut = []
            if(this.ProductList.length == 0){
                return 
            }
            for(var i = 0; i<this.ProductList.length; i++){
                this.filterOut.push({"match": {"ProductID": this.ProductList[i].ID}})
            }
        },
        searchItem: async function({keyword}){
            var object = {
                "Size": this.displaySize,
                "From": this.index,
                "Sort": this.Sort,
                "Source": this.Source,
                "Filter": this.filter,
                "Range": this.range
            }
            if(keyword){
                object["Keyword"] = keyword
                this.selectAll = false;
                this.selectedItem = [],
                this.selectedItemCounter = 0;
                this.index = 0;
                this.pageNumber = 1;
            }
            if(this.filterOut.length > 0){
                object["FilterOut"] = this.filterOut
            }
            try{
                const ret= await this.$store.dispatch('searchProduct',{router:this.router,keyword:object})
                this.setDisplayList(ret.data.data.hits.hits)
                this.isLoading = false;
            }catch(err){
                this.isLoading = false;
                console.log("searchProduct error", err.response.data);
                this.formMessage = err.response.data.message
                this.showFormMessage = true
            }
        },
        setDisplayList(list){
            console.log(list);
            var newlist=[];
            for(var i = 0; i<list.length; i++){
            var object = {
                "ID": list[i]["_source"].ProductID,
                "Product Name": list[i]["_source"].Name.en + " / " + list[i]["_source"].Name.zh,
                "Type": list[i]["_source"].ProductType.Name.en,
                "Minimum Price": list[i]["_source"].MinimumPrice,
            }
            if(list[i]["_source"].Image.length > 0){
                object["img"] = process.env.VUE_APP_S3_URL+"product/"+list[i]["_source"].ProductID+"/" + list[i]["_source"].Image[0].ImageID
            }
            newlist.push(object)
            }
            this.TableItemList = newlist;
            this.$forceUpdate();
            this.TableSelectUIControl(this.TableItemList)
        },
        TableSelectUIControl(ItemList){
            this.showNextButton = true;
            if(ItemList.length < this.displaySize){
                this.showNextButton = false;
            }
            var counter = 0;
            for(var i=0; i<ItemList.length;i++){
                var index = this.selectedItem.indexOf(ItemList[i].ID);
                if(index > -1){
                    counter++
                }
            }
            if(counter ==  ItemList.length){
                this.selectAll = true;
            }else{
                this.selectAll = false;
            }
        },
        
        selectSortOption: function(option){
            console.log("charge sorting with " + option);
            for(var i = 0; i<this.sortOption.length; i++){
                if(this.sortOption[i].Key == option){
                    this.sortOption[i].Value = true
                }else{
                    this.sortOption[i].Value = false
                }
            }
            var object = {}
            object[option] = {"order" : "desc"}
            this.Sort = [object]
            this.showSortPopup = false;
            this.searchItem({keyword:""});
        },
        onclickItem: function(ID){
            var index = this.selectedItem.indexOf(ID)
            if(index > -1){
                this.selectedItem.splice(index, 1);
            }else{
                this.selectedItem.push(ID)
            }
            this.selectedItemCounter = this.selectedItem.length
        },
        selectedItemActionFunction: async function(action){
            if(action == "addselect"){
                for(var i = 0; i<this.selectedItem.length;i++){
                    var index = this.ProductList.findIndex(product => product.ID == this.selectedItem[i]);
                    if(index < 0){
                        this.filterOut.push({"match": {"ProductID": this.selectedItem[i].split('::')[0]}})  
                    }
                }
                var productreuslt = await this.getProduct(this.selectedItem)
                console.log(productreuslt);
                var selectedProductList = []
                for(var i = 0; i<productreuslt.length; i++){
                    var objetc = {
                        "ID": productreuslt[i].ProductID,
                        "img": this.s3path+productreuslt[i].Image[0].path,
                        "ProductName": productreuslt[i].Name.en+" / "+productreuslt[i].Name.zh,
                        "MinimumPrice": productreuslt[i].MinimumPrice,
                        "Parameter": productreuslt[i].ProductType.Parameter,
                        "Survey": []
                    }
                    selectedProductList.push(objetc)
                }  
                //this.ProductList = selectedProductList
                this.selectedItem = [];
                this.selectedItemCounter = this.selectedItem.length
                this.showSelectProduct = false;
                this.$emit("setProductList", selectedProductList);
            }
        },
        goPage:function(action){
            if(action == "next"){
                this.index = this.index + this.displaySize
                this.pageNumber = this.pageNumber + 1
            }else if(action == "prev" && this.index > 0){
                this.index = this.index - this.displaySize
                this.pageNumber = this.pageNumber - 1
            }else{
                this.index = 0
                this.pageNumber = 1
            }
            this.searchItem({keyword:""});
        },
        setFilter: function (filterOption){
      console.log("setFilter", filterOption);
        this.filter = {};
      for(var f in filterOption){
        var option = filterOption[f]
        if(option.type == "range"){
          var filterObject = {}
          if(option.from){
            filterObject["Start"] = option.from
          }
          if(option.to){
            filterObject["End"] = option.to
          }
          if(filterObject.Start || filterObject.End){
            this.range[option.Key] = filterObject
          }
        }else{
          var filterList = [];
          for(var o in option.option){
            if(option.option[o].Value){
              filterList.push(option.option[o].Key)
            }
          } 
          if(filterList.length > 0){
            this.filter[option.Key] = filterList
          }
        }
      } 
        this.selectAll = false;
        this.selectedItem = [],
        this.selectedItemCounter = 0;

        this.index = 0;
        this.pageNumber = 1;
        console.log("filter", this.filter)
        this.searchItem({keyword:""});
      },
        SortPopupControl: function (){
            this.showSortPopup = !this.showSortPopup
        },
        /*
            Explor Function
        */
        setMinHeight:function(minHeight){
            if(minHeight==0){
                minHeight = "140px";
            }else{
                minHeight = minHeight+"px";
            }

            return {
                "max-Height": minHeight,
                "min-Height": minHeight
            }
        },
        expandItem:function(element){
            let iconClass = document.getElementById(element+this.icon)
            let contentClass = document.getElementById(element+this.content)
            console.log("iconClass", iconClass)
            if(document.getElementById(element).checked){
                iconClass.classList.remove('iconup')
                iconClass.classList.add('icondown')
            }else{
                iconClass.classList.remove('icondown')
                iconClass.classList.add('iconup')
            }
            document.getElementById(element).checked = !document.getElementById(element).checked;
            if(document.getElementById(element).checked){
                console.log("scrollHeight", contentClass.scrollHeight)
                contentClass.style.minHeight = contentClass.scrollHeight + "px";
                contentClass.style.maxHeight = contentClass.scrollHeight + "px";
            }else{
                contentClass.style.maxHeight = "0px";
                contentClass.style.minHeight = "0px";
            }      
        },
    },
    watch: {
        showSelectProduct: function(val){
        var rootClass = document.getElementsByClassName("md-theme-default")[0];
        if(val){
          /*
            set scroll-y to hide the scrollbar
          */
          rootClass.style.overflowY = "hidden"; 
        }else{
          rootClass.style.overflowY = "auto";
        }
      }
    },
    props: {
        ProductList:{
            default() {
                return [];
            },
        },
        mode: {
            default (){
                return "Create"
            }
        },
        Status: {
            default (){
                return "Draft"
            }
        },
    },
    data() {
        return {
            showFormMessage: false,
            showDeleteParameterPopup: false,
            showDeleteProductPopup: false,
            formMessage: "",
            SurveyTemplateInput:{
                "key": "ProductSurvey",
                "name": "問卷",
                "required": true,
                "value": null,
                "option": []
            },
            icon: "icon",
            content: "content",
            isLoading: false,
            showSortPopup: false,
            showSelectProduct: false,
            showAddParamter: false,
            newAddParameterID: "",
            newAddParameterList: [],
            newAddParameterSurvey: {
            },
            s3path: process.env.VUE_APP_S3_URL,
            index: 0,
            pageNumber: 1,
            showNextButton: true,
            displaySize: 10,
            columns: ["img", "Product Name", "Type", "Minimum Price"],
            Source: ["Name", "ProductType.Name", "MinimumPrice", "SupplierID", "Image", "ProductID", "Status"],
            TableItemList: [],
            Sort: {"CreatedDatetime": "desc"},
            sortOption: [
                {Key: "CreatedDatetime", Name: "Recent Added", Value: true, Order: "desc"},
                {Key: "MinimumPrice", Name: "Minimum Price", Value: false, Order: "desc"},
            ],
            selectedItemAction: [
                {Key: "addselect", Name: "Add Selected"},
            ],
            filterOption:[
                {
                    Key: "ProductType", 
                    Name: "Type", 
                    type: "checkbox",
                    inputType: "checkbox",
                    option:[]
                },
                {
                    Key: "MinimumPrice",
                    Name: "Minimum Price", 
                    type: "range",
                    inputType: "number",
                    from: "",
                    to: ""
                }
            ],
            selectedItem: [],
            selectedItemCounter: 0,
            selectAll: false,
            selectable: true,
            haveSearchFunction: true,
            filter: {},
            range: {},
            filterOut: [],
            ProductTypeList: [],
            SupplierList: [],
            DeleteParameterTitle: "刪除參數",
            DeleteParameterContent: "確定要刪除此參數嗎？",
            actionButton: "Delete",
            delSurveyID: "",
            delParameterProductID: "",
            delProductID: "",
            DeleteProductTitle: "刪除產品",
            DeleteProductContent: "確定要刪除此產品嗎？",

        };
    },
    computed: {

    }
}
</script>
<style>
    .parameter{
        padding: 10px 0px;
        margin-bottom: 10px;
        border-radius: 8px;
        border: 2px solid gray;
        background-color: #f2f2f2;
    }
    .parameter label{   
        font-size: 15px;
    }
    .addParamter{
        color: #575757;
        font-size: 20px;
        font-weight: 300;
        cursor: pointer;
    }
    .remove{
        color: #575757;
        font-size: 14px;
        font-weight: 300;
        color: red !important;
        cursor: pointer;
    }
    .displayItem{
        text-align: left;
    }
    .displayImage{
        height: 200px !important;
        width: 200px !important;
    }
    .buttonPosition{
        position: absolute;
        right: 35px;
    }
    .newProductButton{
        min-width: 100px;
        padding: 10px 20px;
        background-color: #575757;
        font-size: 16px;
        color: white;
        border: none;
        border-radius: 5px;
        font-weight: 400; 
    }
    .collapsible{
        overflow: hidden;
        cursor: pointer;
    }
    .collapsible label{
        cursor: pointer;
    }
    .collapsible-item{
        max-height: 1px;
        overflow: hidden;
        position: relative;
        top: -100%;
        opacity: 0.5;
        transition:all 0.5 ease;
    }
    .collapsible input:checked ~ .collapsible-item{
        overflow-y: auto;
        /* min-height: 140px; */
        opacity: 1;
        top: 0;
    }
    .unsetMargin{
        margin: unset;
        background-color: grey;
    }
    .explorTitle{
        padding: 15px 0;
        color: white;
        font-size: 20px;
        font-weight: 300;
        text-align: left;
    }

    .explorIcon{
        padding: 15px 13px;
        width: 50px;
        height: 50px;
        text-align: left;
        font-size: 18px !important;
        font-weight: 300 !important;
    }
    .icondown {
        animation-name: rotationdown;
        animation-duration: 0.5s;
        animation-direction: normal; 
        transform: rotate(180deg);
    }
    @keyframes rotationdown {
        from {
        transform: rotate(0deg);
        }
        to {
        transform: rotate(180deg);
        }
    }
    .iconup {
        animation-name: rotationup;
        animation-duration: 0.5s;
        animation-direction: normal; 
        transform: rotate(0deg);
    }
    @keyframes rotationup {
        from {
        transform: rotate(180deg);
        }
        to {
        transform: rotate(0deg);
        }
    }

    .centerButton{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>