
<template>
    <div style="text-align:left;">
        <div class="row">
          <div style="width:50%"> <h3 class="formTitle">Product List</h3></div>
          <div v-if="editAble" class="action" @click="addproduct()"><u>add product</u> </div>
        </div>
        <table v-if="(ProductList.length > 0)" class="productSelecterTable">
          <tr v-for="product in ProductList" :key="product.ID">
            <td style="width: 100px;max-width: 100px;">
              <img class="productImg" :src=product.img>
            </td>
            <td>
              <div class="column productText">
                <div> {{ product["Product Name"] }}</div>
                <div>$ {{ product["Minimum Price"] }}</div> 
              </div>
            </td>
            <td v-if="(haveQuantity)">
              <label>Quantity</label>
              <md-field style="margin-top:0px;padding-top:5px">
                <md-input type="number" value=1 class="formInput" v-model=product.Quantity />
              </md-field>
            </td>
            <td v-if="(haveOption)" style="text-align: right;">         
                <md-field style="margin-top:0px;padding-top:0px">
                  <md-select v-model=product.Option class="formInput" :disabled="!editAble">
                    <md-option v-for="option in Option" :key="option.key" :value=option.key :disabled="!editAble">{{option.name}}</md-option>
                  </md-select>
                </md-field>
            </td>
            <td style="text-align: right;">
              <div v-if="editAble" class="productRemove"  @click="removeproduct(product.ID)"><u>remove</u></div>
            </td>
          </tr>
        </table>
        <div v-else style="text-align:center">
            No Product <br>
            <md-button v-if="editAble" style="background-color: #575757 !important;width: 35% !important; margin-top: 20px;" @click="addproduct()">Add Product</md-button>
        </div>
        <Transition name="modal" v-if="showSelectProduct">
            <div  class="modal-mask">
                <div class="modal-wrapper">
                    <div class="product-select-container">
                        <div class="popup">
                            <div class="row" style="padding: 0 10px">
                                <md-button class="backbutton" style="background-color:white !important;" @click="showSelectProduct=false">
                                    <md-icon class="backIcon" style="color:black !important;margin-left: -10px;">arrow_back_ios</md-icon>
                                </md-button>
                                <h3 class="title" style="flex: 1; padding:15px 0px 0px 10px"> {{HeaderTitle}}</h3>
                            </div>

                            <md-card>
                            <md-card-content>
                                <TableComponent 
                                v-on:selectItem="selectItem"
                                v-on:selectAllItem="selectAllItem"
                                v-on:searchItem="searchItem"
                                v-on:selectSortOption="selectSortOption"
                                v-on:onclickItem="onclickItem"
                                v-on:selectedItemActionFunction="selectedItemActionFunction"
                                v-on:goPage="goPage"
                                v-on:SortPopupControl="SortPopupControl"
                                v-on:setFilter="setFilter"
                                :TableList="TableItemList"
                                :selectedItemCounter="selectedItemCounter"
                                :selectedItem="selectedItem"
                                :columns="columns"
                                :selectAll="selectAll"
                                :selectable="selectable"
                                :haveSearchFunction="haveSearchFunction"
                                :sortOption="sortOption"
                                :selectedItemAction="selectedItemAction"
                                :pageNumber="pageNumber"
                                :showNextButton="showNextButton"
                                :showSortPopup="showSortPopup"
                                :filterOption="filterOption"
                                ></TableComponent>
                            </md-card-content>
                            </md-card>


                        </div> 
                    </div>
                </div>
            </div>
        </Transition>
    </div>
    
</template>

<script>
import  TableComponent from "./Table.vue";

export default {
    components: {
        TableComponent,
    },
  methods:{
    getSuppliers: async function(){
      try{
        const ret= await this.$store.dispatch("listSuppliers",{router:this.router})
        for(var i in ret.data.supplierList){
          this.SupplierList.push({
            SupplierID: ret.data.supplierList[i].SupplierID,
            Name: ret.data.supplierList[i].Name,
          })
        }
      }
      catch(err){
        console.log(err.message);
      }
    },
    getProduct: async function(ProductList){
      try{
        const ret= await this.$store.dispatch("getProduct",{router:this.router, product:{ProductList:ProductList}})
        //console.log("getProduct", ret.data);
        return ret.data.ProductDetailList;
      }
      catch(err){
        console.log(err.message);
      }
    },
    getProductType: async function(){
      try{
        const ret= await this.$store.dispatch("getProductType",{router:this.router, data:null})
        console.log("getProductType", ret.data);
        this.ProductTypeList = ret.data.ProductType;
      }
      catch(err){
        console.log(err.message);
      }
    },
    addproduct: async function(){
        console.log("go to add product");
        await this.searchItem({keyword:""});
        this.showSelectProduct = true
    },
    removeproduct:function(ID){
        var index = this.ProductList.findIndex(product => product["ID"] == ID)
        this.ProductList.splice(index, 1);
        var filterOutIndex = this.filterOut.findIndex(item => item.match.ProductID == ID.split('::')[0]);
        this.filterOut.splice(filterOutIndex, 1);
    },
    setFilter: function (filterOption){
      console.log(filterOption);
        this.filter = [];
        console.log("setFilter", filterOption)
        for(var i = 0; i<filterOption.length; i++){
          console.log(filterOption[i].Key);
          if(filterOption[i].type == "range"){
            var rangeObject = {}
            if(filterOption[i].from){
              rangeObject["gte"] = filterOption[i].from
            }
            if(filterOption[i].to){
              rangeObject["lte"] = filterOption[i].to
            }
            if(rangeObject.gte || rangeObject.lte){
              var range = {}
              range[filterOption[i].Key] = rangeObject
              this.filter.push({range})
            }
          }else{
            var object = {
              bool:{
                should: []
              }   
            }
            for(var x = 0; x<filterOption[i].option.length; x++){
              if(filterOption[i].option[x].Value == true){
                var match = {}
                match[filterOption[i].Key] = filterOption[i].option[x].Key
                object.bool.should.push({match}) 
              }
            }
            this.filter.push(object)
          }
        }
        this.selectAll = false;
        this.selectedItem = [],
        this.selectedItemCounter = 0;

        this.index = 0;
        this.pageNumber = 1;
        console.log("filter", this.filter)
        this.searchItem({keyword:""});
    },
    SortPopupControl: function (){
    this.showSortPopup = !this.showSortPopup
    },
    goPage:function(action){
      if(action == "next"){
        this.index = this.index + this.displaySize
        this.pageNumber = this.pageNumber + 1
      }else if(action == "prev" && this.index > 0){
        this.index = this.index - this.displaySize
        this.pageNumber = this.pageNumber - 1
      }else{
        this.index = 0
        this.pageNumber = 1
      }
      this.searchItem({keyword:""});
    },
    selectedItemActionFunction: async function(action){
      console.log("selectedItemActionFunction", this.selectedItem)
      if(action == "addselect"){
        for(var i = 0; i<this.selectedItem.length;i++){
            var index = this.ProductList.findIndex(product => product.ID == this.selectedItem[i]);
            if(index < 0){
                this.filterOut.push({"match": {"ProductID": this.selectedItem[i].split('::')[0]}})  
            }
        }
        var productreuslt = await this.getProduct(this.selectedItem)
        this.selectedProductList = []
        for(var i = 0; i<productreuslt.length; i++){
          var objetc = {
            "ID": productreuslt[i].ProductID,
            "img": this.s3path+productreuslt[i].Image[0].path,
            "Product Name": productreuslt[i].Name.en+" / "+productreuslt[i].Name.zh,
            "Minimum Price": productreuslt[i].MinimumPrice
          }
          if(this.haveQuantity){
            objetc["Quantity"] = 1
          }
          if(this.haveOption){
            objetc["Option"] = null
          }
          this.selectedProductList.push(objetc)
        }  

        this.selectedItem = [];
        this.selectedItemCounter = this.selectedItem.length
        this.showSelectProduct = false;
        this.$emit("setProductList", this.selectedProductList);
      }
    },
    setFilterOut: function(){
        this.filterOut = []
        if(this.ProductList.length == 0){
            return 
        }
        for(var i = 0; i<this.ProductList.length; i++){
            this.filterOut.push({"match": {"ProductID": this.ProductList[i].ID}})
        }
    },
    selectSortOption: function(option){
      console.log("charge sorting with " + option);
        for(var i = 0; i<this.sortOption.length; i++){
          if(this.sortOption[i].Key == option){
            this.sortOption[i].Value = true
          }else{
            this.sortOption[i].Value = false
          }
        }
        var object = {}
        object[option] = {"order" : "desc"}
        this.Sort = [object]
        this.showSortPopup = false;
        this.listItem();
    },
    selectAllItem: async function(action, {keyword}){
        console.log("action", action);
        console.log("keyword", keyword);
        if(!action){
            this.selectAll = true;
            this.isLoading = true;
            var object = {
                "Source": this.Source,
                "Filter": this.filter,
                "Range": this.range
            }
            if(keyword){
                object["Keyword"] = keyword
            }
            if(this.filterOut.length > 0){
                object["FilterOut"] = this.filterOut
            }
            try{
                const ret= await this.$store.dispatch('searchProduct',{router:this.router,keyword:object})
                this.selectedItem = ret.data.data.hits.hits.map(item => String(item._id))
                this.isLoading = false;
                }catch(err){
                
                this.formMessage = err.response.data.message
                this.showFormMessage = true
                } 
            }else{
                this.selectAll = false;
                this.selectedItem = []
            }
        console.log("after", this.selectedItem);
        this.selectedItemCounter = this.selectedItem.length
    },
    onclickItem: function(ID){
        var index = this.selectedItem.indexOf(ID)
        if(index > -1){
            this.selectedItem.splice(index, 1);
        }else{
            this.selectedItem.push(ID)
        }
        this.selectedItemCounter = this.selectedItem.length
    },
    selectItem:function(ID, action){
      console.log(ID, action);
      if(action){
        this.selectedItem.push(ID)
      }else{
        this.selectAll = false;
        var index = this.selectedItem.indexOf(ID)
        this.selectedItem.splice(index, 1);
      }
      console.log(this.selectedItem.length);
      this.selectedItemCounter = this.selectedItem.length

    },
    searchItem: async function({keyword}){
        var object = {
            "Size": this.displaySize,
            "From": this.index,
            "Sort": this.Sort,
            "Source": this.Source,
            "Filter": this.filter,
            "Range": this.range
        }
        if(keyword){
            object["Keyword"] = keyword
            this.selectAll = false;
            this.selectedItem = [],
            this.selectedItemCounter = 0;
            this.index = 0;
            this.pageNumber = 1;
        }
        if(this.filterOut.length > 0){
            object["FilterOut"] = this.filterOut
        }
        try{
            const ret= await this.$store.dispatch('searchProduct',{router:this.router,keyword:object})
            this.setDisplayList(ret.data.data.hits.hits)
            this.isLoading = false;
        }catch(err){
            this.isLoading = false;
            console.log("searchProduct error", err.response.data);
            this.formMessage = err.response.data.message
            this.showFormMessage = true
        }
    },
    setDisplayList(list){
      console.log(list);
        var newlist=[];
        for(var i = 0; i<list.length; i++){
          var object = {
            "ID": list[i]["_source"].ProductID,
            "Product Name": list[i]["_source"].Name.en + " / " + list[i]["_source"].Name.zh,
            "Type": list[i]["_source"].ProductType.Name.en,
            "Minimum Price": list[i]["_source"].MinimumPrice,
          }
          if(list[i]["_source"].Image.length > 0){
            object["img"] = process.env.VUE_APP_S3_URL+"product/"+list[i]["_source"].ProductID+"/" + list[i]["_source"].Image[0].ImageID
          }
          newlist.push(object)
        }
        this.TableItemList = newlist;
        this.TableSelectUIControl(this.TableItemList)
      },

    TableSelectUIControl(ItemList){
        this.showNextButton = true;
        if(ItemList.length < this.displaySize){
            this.showNextButton = false;
        }
        var counter = 0;
        for(var i=0; i<ItemList.length;i++){
            var index = this.selectedItem.indexOf(ItemList[i].ID);
            if(index > -1){
                counter++
            }
        }
        if(counter ==  ItemList.length){
            this.selectAll = true;
        }else{
            this.selectAll = false;
        }
    }
  },
  name: "product-selecter-component",
  props: {
    ProductList:{
        type: Array,
        default(){
          return []
        }
      },
    HeaderTitle:{
        type: String,
        default(){
          return "Add Product to"
        }
    },
    editAble: {
      default(){
        return true
      }
    },
    haveQuantity:{
      default(){
        return false
      }
    },
    haveOption:{
      default(){
        return false
      }
    },
    Option: {
      default(){
        return []
      }
    },
    displaySize: {
      default(){
        return 10
      }
    }
  },
  data() {
    return {
        s3path: process.env.VUE_APP_S3_URL,
        showSelectProduct: false,
        index: 0,
        pageNumber: 1,
        showNextButton: true,
        showSortPopup: false,
        columns: ["img", "Product Name", "Type", "Minimum Price"],
        Sort: {"CreatedDatetime": "desc"},
        sortOption: [
            {Key: "CreatedDatetime", Name: "Recent Added", Value: true, Order: "desc"},
            {Key: "MinimumPrice", Name: "Minimum Price", Value: false, Order: "desc"},
        ],
        selectedItemAction: [
            {Key: "addselect", Name: "Add Selected"},
        ],
        filterOption:[
          {
              Key: "ProductType", 
              Name: "Type", 
              type: "checkbox",
              inputType: "checkbox",
              option:[
              {
                  "Key": "Coffee",
                  "Name": "Coffee",
                  "Value": false
              },
              {
                  "Key": "Tea",
                  "Name": "Tea",
                  "Value": false
              },
              {
                  "Key": "Alcohol",
                  "Name": "Alcohol",
                  "Value": false
              }
              ]
          },
          {
              Key: "UnitPrice",
              Name: "Unit Price", 
              type: "range",
              inputType: "number",
              from: "",
              to: ""
          }
        ],
        selectedItem: [],
        selectedItemCounter: 0,
        selectAll: false,
        selectable: true,
        haveSearchFunction: true,
        filter: [],
        filterOut: [],
        ProductTypeList: [],
        SupplierList: [],
    };
  },
 async created() {
      await this.getProductType();
      await this.getSuppliers();
      //this.listItem();
    },
  computed: {

  }
};
</script>

<style>
    .productSelecterTable{
        width: 100%;
    }
    .productSelecterTable tr td{
      border: none !important;
    }
    .productRemove{
        font-size: 18px;
        padding-top:25px;
        color:#CC2727;
        cursor: pointer;
    }
    .productText{
        font-size: 20px;
        padding-top:25px;
        padding-left:20px;
    }
    .productImg{
        width: 100px !important;
        height: 100px !important;
    }
    .backButtonTitlePadding{
      padding-left: 20px;
    }
    .backIcon{
      padding: unset;
      margin: unset;
      color:black !important;
    }
    .backbutton{
      padding: unset;
      margin: 15px 0 0 0 !important;
      min-width: 40px !important;
      width: 40px !important;
      height: 45px !important;
    }
    .formTitle{
      padding-left: 15px;
      margin-top: 10px;
      font-weight: 500;
    }
    .action{
        text-align:right;
        width:50%;
        margin: 10px 0;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
    }
    .product-select-container{
        max-width: 90%;
        height: 900px;
        margin: 0px auto;
        position: relative;
        background-color: #fff;
        transition: all .3s ease;
        box-shadow: 0 27px 24px 0 rgba(0,0,0,.2), 0 40px 77px 0 rgba(0,0,0,.22);
        border-radius: 6px;
        border: none;
    }
</style>
