<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <FormHeader
                    :HeaderTitle="survey.Name"
                    :confirmBack="confirmBack"
                />
            </div>
            <md-card>
                <md-card-content class="surveyResult">
                    <div v-for="(questions, index) in questions" :key="index">
                        <div v-if="(questions.Type == 'FiveSenses')">
                           <h3>五味</h3>
                            <RadarChart
                            :width="barChartSize.width" 
                            :height="barChartSize.height"
                            :chartData="{'labels':Object.keys(questions.taste),'datasets':[{'data':Object.keys(questions.taste).map(a=>questions.taste[a]),backgroundColor: 'rgba(255,99,132,0.2)',borderColor: 'rgba(255,99,132,1)',pointBackgroundColor: 'rgba(255,99,132,1)',pointBorderColor: '#fff',pointHoverBackgroundColor: '#fff',pointHoverBorderColor: 'rgba(255,99,132,1)','label':survey.Name}]}"
                            :options="radarChartOptions"
                            />
                            <div v-for="(answer, i) in questions.Analytics" :key="i">
                                <h5>{{ getFiveSenses(answer.Question) }}</h5>
                                <table class="surveyResultTable">
                                    <tr>
                                        <td v-for="(value, key) in answer.Answer" :key="key">
                                            {{ getFiveSensesLevel(answer.Question, key) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td v-for="(value, key) in answer.Answer" :key="key">
                                            {{ value.Count }} ( {{ value.Percentage }})
                                        </td>
                                    </tr>
                                </table>
                            </div>

                        </div>
                        <div v-else-if="(questions.Type == 'TasteNote')">
                            <h3>風味</h3>
                            <div v-for="(answer, i) in questions.Analytics" :key="i">
                                <wordcloud
                                :data="answer.map(a => ({'name':a.Name.zh,'value':a.Count}))"
                                nameKey="name"
                                valueKey="value"
                                :wordPadding=0
                                :showTooltip="true"
                                :wordClick="()=>{}"
                                :fontSize="[20, 160]"
                                :rotate="{from: 0, to: 90, numOfOrientation: 0 }">
                                </wordcloud>
                                
                                <table class="surveyResultTable">
                                    <tr>
                                        <td v-for="(value, key) in answer" :key="key">
                                            {{ value.Name.zh }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td v-for="(value, key) in answer" :key="key">
                                            {{ value.Count }} ( {{ value.Percentage }} ) 
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div v-else-if="(questions.Question == 'Aroma')">
                            <h3>香氣</h3>
                            <div v-for="(answer, i) in questions.Analytics" :key="i">
                                <wordcloud
                                :data="answer.map(a => ({'name':a.Name.zh,'value':a.Count}))"
                                nameKey="name"
                                valueKey="value"
                                :wordPadding=0
                                :showTooltip="true"
                                :wordClick="()=>{}"
                                :fontSize="[20, 160]"
                                :rotate="{from: 0, to: 90, numOfOrientation: 0 }">
                                </wordcloud>
                                
                                <table class="surveyResultTable">
                                    <tr>
                                        <td v-for="(value, key) in answer" :key="key">
                                            {{ value.Name.zh }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td v-for="(value, key) in answer" :key="key">
                                            {{ value.Count }} ( {{ value.Percentage }} ) 
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div v-else-if="(questions.Question == 'CustomTasteNote')">
                            <h3>自訂風味</h3>
                            <wordcloud
                            :data="Object.keys(questions.Answer).map(k => ({'name':k,'value':questions.Answer[k]}))"
                            nameKey="name"
                            valueKey="value"
                            :wordPadding=0
                            :showTooltip="true"
                            :wordClick="()=>{}"
                            :fontSize="[20, 160]"
                            :rotate="{from: 0, to: 90, numOfOrientation: 0 }">
                            </wordcloud>
                            <table class="surveyResultTable">
                                <tr>
                                    <td v-for="(key) in questions.sorted" :key="key">
                                        <div >
                                            {{ key }}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td v-for="(key) in questions.sorted" :key="key">
                                        <div >
                                            {{ questions.Analytics[key].Count }} ( {{ questions.Analytics[key].Percentage }} )
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div v-else-if="(questions.Question == 'CustomAroma')">
                            <h3>自訂香氣</h3>
                            <wordcloud
                            :data="Object.keys(questions.Answer).map(k => ({'name':k,'value':questions.Answer[k]}))"
                            nameKey="name"
                            valueKey="value"
                            :wordPadding=0
                            :showTooltip="true"
                            :wordClick="()=>{}"
                            :fontSize="[20, 160]"
                            :rotate="{from: 0, to: 90, numOfOrientation: 0 }">
                            </wordcloud>
                            <table class="surveyResultTable">
                                <tr>
                                    <td v-for="(key) in questions.sorted" :key="key">
                                        <div >
                                            {{ key }}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td v-for="(key) in questions.sorted" :key="key">
                                        <div >
                                            {{ questions.Analytics[key].Count }} ( {{ questions.Analytics[key].Percentage }} )
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div v-else-if="(questions.Type == 'Text')">
                            <h3>{{ getQuestion(questions.Question) }}</h3>
                            <div v-for="(answer, i) in questions.Answer" :key="i">
                                <p>{{ i }}</p>
                            </div>
                        </div>
                        <div v-else>
                            <h3>{{ getQuestion(questions.Question) }}</h3>
                            <BarChart 
                            :width="barChartSize.width" 
                            :height="barChartSize.height" 
                            :chartData="{'labels':Object.keys(questions.Answer),'datasets':[{'data':Object.keys(questions.Answer).map(a=>questions.Answer[a]),'backgroundColor':colorArr,label:Object.keys(questions.Answer)}]}"
                            :options="pieChartOptions" 
                            />
                            <table class="surveyResultTable">
                                    <tr>
                                        <td v-for="(key) in questions.sorted" :key="key">
                                            <div >
                                                {{ key }}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td v-for="(key) in questions.sorted" :key="key">
                                            <div >
                                                {{ questions.Analytics[key].Count }} ( {{ questions.Analytics[key].Percentage }} )
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                        </div>
                       
                    </div>
                </md-card-content>
            </md-card>
        </div>
        <Spinner v-if="isLoading"/>
    </div>
</template>
<script>

import { 
  FormHeader,
  Spinner
} from "@/components";

import BarChart from '@/components/Charts/BarChart.vue'
import RadarChart from '@/components/Charts/RadarChart.vue'
import wordcloud from 'vue-wordcloud'

export default {
    components: {
        FormHeader,
        Spinner,
        BarChart,
        RadarChart,
        wordcloud
  },
    async created() {
        console.log("SurveyResultDetail", this.$route.params);
        this.isLoading = true;
        await this.getSurvey(this.$route.params.surveyID);
        await this.getSurveyResult(this.$route.params.surveyID, this.$route.params.varianceID);
        this.isLoading = false;
        
    },
    methods:{
        getQuestion: function(question){
            var text = ""
            if(question == "CustomTasteNote")
            {
                text = "自訂風味"
            }else if(question == "CustomAroma"){
                text = "自訂香氣"
            }else{
                text = question
            }
            return text;
        },
        getFiveSenses: function(fiveSenses){
            const text = this.FiveSenses[fiveSenses]
            return text;
        },
        getFiveSensesLevel: function(fiveSenses, level){
            const FiveSenses = this.FiveSenses[fiveSenses];
            const Level = this.levelStr[level];
            var text = "";
            if(level == 2){
                text = Level
            }else{
                text = Level + FiveSenses
            }
            return text;
        },
        getSurvey: async function (id){
            try{
                const ret= await this.$store.dispatch('getSurvey',{router:this.router, data:{SurveyID:id}})
                this.survey = ret.data.Survey
            }catch(err){
                console.log("getSurvey error", err.response.data);
                this.message = err.response.data.message
                this.showMessage = true
            }

        },
        getSurveyResult: async function(surveyID, varianceID){
            var object = {
                SurveyID: surveyID,
                VarianceID: varianceID
            };
            try{
                const ret= await this.$store.dispatch('getSurveyResult',{router:this.router, data:object})
                console.log("getSurveyResult", ret.data);
                this.questions = ret.data.Question
                this.totalCount = ret.data.TotalCount
            }catch(err){
                console.log("getSurveyResult error", err.response.data);
                this.message = err.response.data.message
                this.showMessage = true
            }
            this.questions.forEach((q)=>{
                if(q.Type=='FiveSenses'){
                    //console.log(q)
                    q.taste={}
                    q.Answer.forEach((t)=>{
                        q.taste[this.getFiveSenses(t.Question)]=(Object.keys(t.Answer).map((val)=>parseInt(t.Answer[val])*parseInt(val)).reduce((sum,val)=>sum+val))/Object.keys(t.Answer).length
                    });
                    //console.log(q)
                }
                else if(q.Type=='TasteNote' || q=='CustomTasteNote'){
                    //console.log(q.Analytics);
                    q.Analytics.forEach((sq,i)=>{
                        q.Analytics[i]=sq.sort((a,b) => a.Count - b.Count).reverse();
                    })
                    
                    //console.log(q);
                }
                else{
                    console.log(q);
                    q.sorted=Object.keys(q.Analytics).sort((a,b) => q.Analytics[a].Count - q.Analytics[b].Count).reverse();
                }
                
            })
        },
    },
    data() {
        return {
            isLoading: true,
            confirmBack:false,
            survey: {},
            questions: [],
            sortedResult:[],
            totalCount: 0,
            showNumber: true,
            FiveSenses: {
                "Sweetness": "甜",
                "Sourness": "酸",
                "Bitterness": "苦",
                "Saltiness": "鹹",
                "Umami": "鮮",
            },
            levelStr: {
                "0": "不",
                "1": "微",
                "2": "中度",
                "3": "很",
                "4": "極"
            },
            barChartSize:{
                width:400,
                heihgt:400
            },
            colorArr:[
                'rgb(255, 0, 41)',
                'rgb(55, 126, 184)',
                'rgb(102, 166, 30)',
                'rgb(152, 78, 163)',
                'rgb(0, 210, 213)',
                'rgb(255, 127, 0)',
                'rgb(175, 141, 0)',
                'rgb(127, 128, 205)',
                'rgb(179, 233, 0)',
                'rgb(196, 46, 96)',
                'rgb(166, 86, 40)',
                'rgb(247, 129, 191)',
                'rgb(141, 211, 199)',
                'rgb(190, 186, 218)',
                'rgb(251, 128, 114)',
                'rgb(128, 177, 211)',
                'rgb(253, 180, 98)',
                'rgb(252, 205, 229)',
                'rgb(188, 128, 189)',
                'rgb(255, 237, 111)',
                'rgb(196, 234, 255)',
                'rgb(207, 140, 0)',
                'rgb(27, 158, 119)',
                'rgb(217, 95, 2)',
                'rgb(231, 41, 138)',
                'rgb(230, 171, 2)',
                'rgb(166, 118, 29)',
                'rgb(0, 151, 255)',
                'rgb(0, 208, 103)',
                'rgb(0, 0, 0)',
                'rgb(37, 37, 37)',
                'rgb(82, 82, 82)',
            ],
            pieChartOptions:{
                maintainAspectRatio:false
            },
            radarChartOptions:{
                maintainAspectRatio:false,
                scale: {
                    ticks: {
                        min: 0,
                        max: 4,
                        stepSize:1
                    }
                }
            }
            
        }
    }


}
</script>
<style lang="scss" scoped>
.surveyResult{
    .table, tr, td{
        text-align: center;
        border: 1px solid black;
        
    }
}
</style>

