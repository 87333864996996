import { render, staticRenderFns } from "./SurveyResultDetail.vue?vue&type=template&id=13a28a6d&scoped=true&"
import script from "./SurveyResultDetail.vue?vue&type=script&lang=js&"
export * from "./SurveyResultDetail.vue?vue&type=script&lang=js&"
import style0 from "./SurveyResultDetail.vue?vue&type=style&index=0&id=13a28a6d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13a28a6d",
  null
  
)

export default component.exports