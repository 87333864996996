<template>
<div class="content">
    <div class="md-layout">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <FormHeader
                :HeaderTitle="orderID"
                :formActionButton="formActionButton"
                v-on:formAction="formAction"
            />

            <div class="row" style="width: 100%;">
                <div class="column" style="width: 60%;">
                    <md-card style="margin:10px 0">
                        <md-card-content>
                          <div class="row">
                            <div class="column" style="width: 50%;">

                              <h4 style="font-weight: 600;">訂單內容</h4>
                              <!-- {{ orderDetail }} -->
                              <label>總計: {{ orderDetail.SubTotal }}</label> <br>
                              <label>運費: {{ orderDetail.ShippingFee }}</label> <br>
                              <label>稅項: {{ orderDetail.Taxes }} </label> <br>
                              <div v-if="orderDetail.Discount">   
                                <div v-if="orderDetail.Discount.extraDiscount"> 
                                  <label>
                                    折扣:  {{ orderDetail.Discount.extraDiscount.Description }} : ${{ orderDetail.Discount.extraDiscount.Discount }}
                                  </label>
                                </div>
                                <div v-if="orderDetail.Discount.CampaignList">
                                  <div v-for="(compaign, index) in orderDetail.Discount.CampaignList" :key="index">
                                    <label>
                                      折扣:  {{ compaign.Description.zh }} : ${{ compaign.Discount }}
                                    </label> <br>
                                  </div>
                                </div>
                              </div>
                              <label>總價: {{ orderDetail.Total }}</label> <br>
                            </div>
                            <div class="column" style="width: 50%;text-align: right;">
                              <h4 style="font-weight: 600;">{{ orderDetail.Status }}</h4>
                              <div v-if="DeliveryOrder.length>0">
                                <label>Tracking No. :</label>
                                <table >
                                  <tr v-for="id in DeliveryOrder" :key="id">
                                    <td @click="openTrackOrderPopup(id)" style="cursor: pointer;">{{ id }}</td>
                                    <td style="width: 20px;"><div class="download" @click="downloadPDF(id)"><md-icon>save_alt</md-icon></div></td>
                                  </tr>
                                </table>
                                <button @click="cancelSFOrder()" class="newProductButton">取消運單</button>
                              </div>
                              
                              <!-- <div v-for="id in orderDetail.DeliveryOrder" :key="id">
                                  <label>{{ id }}</label> <div class="download" @click="downloadPDF(id)"><md-icon>save_alt</md-icon></div><br>
                              </div> -->
                            </div>
                          </div>
                          <CargoList
                            :cargos="Cargo"
                            :canEdit="canEdit"
                            v-on:updateCargo="updateCargo"
                          />
                        </md-card-content>
                    </md-card>
                </div>
                <div class="column" style="width: 35%;padding-left: 20px;">
                  <md-card style="margin:10px 0">
                        <md-card-content>
                          <div class="row">
                            <h4 style="font-weight: 600;">寄件人資料&取貨地址</h4>
                            <u v-if='canEdit' class="editButton" @click="setEditSender()">Edit</u>
                          </div>
                          <label>聯絡人: {{ sender.contact }}</label> <br>
                          <label>公司: {{ sender.company }}</label> <br>
                          <label>電話: {{ sender.mobile }}</label> <br>
                          <label>國家: {{ sender.country }}</label> <br>
                          <label>城市: {{ sender.city }}</label> <br>
                          <label>縣/區: {{ sender.county }}</label> <br>
                          <label>省: {{ sender.province }}</label> <br>
                          <label>地址: {{ sender.address }}</label> <br>
                        </md-card-content>
                    </md-card>
                    <md-card style="margin:10px 0">
                        <md-card-content>
                          <div class="row">
                            <h4 style="font-weight: 600;">收件資料</h4>  
                            <u v-if='canEdit' class="editButton" @click="setEditReceiver()">Edit</u>
                          </div>
                          <label>聯絡人: {{ recipient.contact }}</label> <br>
                          <label>電話: {{ recipient.mobile }}</label> <br>
                          <label>國家: {{ recipient.country }}</label> <br>
                          <label>城市: {{ recipient.city }}</label> <br>
                          <label>縣/區: {{ recipient.county }}</label> <br>
                          <label>省: {{ recipient.province }}</label> <br>
                          <label>地址: {{ recipient.address }}</label> <br>
                        </md-card-content>
                    </md-card>
                    <md-card style="margin:10px 0">
                        <md-card-content>
                            <h4 style="font-weight: 600;">用戶資料</h4>
                           
                                <label>姓氐: {{ user.FamilyName }}</label> <br>
                                <label>名子: {{ user.GivenName }}</label> <br>
                                <label>電話: +{{ user.PhoneCountryCode }}  {{ user.Phone }}</label> <br>
                                <label>電郵: {{ user.Email }}</label> <br>
                                <label>性別: {{ user.Gender }}</label> <br>
                                <label>出生日期: {{ user.Birthday }}</label> <br>
                          
                        </md-card-content>
                    </md-card>

                </div>

            </div>
        </div>
    </div>
    <Transition name="modal" v-if="showEditReceiver">
      <div  class="modal-mask">
          <div class="modal-wrapper">
              <div class="modal-container">
                  <div class="popup">
                      <div class="modal-header">
                          <slot name="header">修改收件資料</slot>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col" style="width: 50%;">
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="senderReceiverInput.contact"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="senderReceiverInput.country"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="senderReceiverInput.city"
                            />
                          </div>
                          <div class="col" style="width: 50%;">
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="senderReceiverInput.mobile"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="senderReceiverInput.province"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="senderReceiverInput.county"
                            />
                          </div>
                        </div>
                        <TextInput
                            v-on:checkEdited="checkEdited"
                            :Item="senderReceiverInput.address"
                          />
                          <div class="row" style="text-align: center;padding-top: 20px;">
                              <div class="column" style="width:47.5%">
                                  <button class="cancelButton" @click="showEditReceiver=false">取消</button>
                              </div>
                              <div class="column" style="width:5%"></div>
                              <div class="column" style="width:47.5%">
                                  <button class="actionButton"  @click="EditReceiver()">確認</button>
                              </div>
                          </div>
                      </div>
                  </div> 
              </div>
          </div>
        </div>
    </Transition>
    <Transition name="modal" v-if="showEditSender">
      <div  class="modal-mask">
          <div class="modal-wrapper">
              <div class="modal-container">
                  <div class="popup">
                      <div class="modal-header">
                          <slot name="header">修改寄件人資料</slot>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col" style="width: 50%;">
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="senderReceiverInput.contact"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="senderReceiverInput.country"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="senderReceiverInput.city"
                            />
                          </div>
                          <div class="col" style="width: 50%;">
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="senderReceiverInput.mobile"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="senderReceiverInput.province"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="senderReceiverInput.county"
                            />
                          </div>
                        </div>
                        <TextInput
                            v-on:checkEdited="checkEdited"
                            :Item="senderReceiverInput.address"
                          />
                          <div class="row" style="text-align: center;padding-top: 20px;">
                              <div class="column" style="width:47.5%">
                                  <button class="cancelButton" @click="showEditSender=false">取消</button>
                              </div>
                              <div class="column" style="width:5%"></div>
                              <div class="column" style="width:47.5%">
                                  <button class="actionButton"  @click="EditSender()">確認</button>
                              </div>
                          </div>
                      </div>
                  </div> 
              </div>
          </div>
        </div>
    </Transition>
    <Transition name="modal" v-if="showRequestPopup">
      <div  class="modal-mask">
          <div class="modal-wrapper">
              <div class="modal-container">
                <div class="popupclose" @click="showRequestPopup=false">
                  <md-icon>close</md-icon>
                </div>
                  <div class="popup">
                      <div class="modal-header">
                          <slot name="header">新增運單</slot>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          
                            <SelectInput
                              v-on:checkEdited="checkEdited"
                              :Item="Request.expressType"
                              style="padding-top: 25px;"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="Request.pickupTime"
                              />
                          
                        </div>
                        <TextInput
                            v-on:checkEdited="checkEdited"
                            :Item="Request.remark"
                          />
                        <div v-if="(showfee)">
                          預計費用: {{ fee }}
                        </div>
                          <div class="row" style="text-align: center;padding-top: 20px;">
                              <div class="column" style="width:47.5%">
                                  <button class="cancelButton" @click="showRequestPopup=false">取消</button>
                              </div>
                              <div class="column" style="width:5%"></div>
                              <div class="column" style="width:47.5%">
                                <!-- <button class="actionButton"  @click="createSFOrder()">確認</button> -->
                                <!-- <button class="actionButton" @click="calculateSF()">計算</button> -->
                                <button class="actionButton"  v-if="(!showfee)" @click="calculateSF()">計算</button>
                                <button class="actionButton"  v-else @click="createSFOrder()">確認</button>
                              </div>
                          </div>
                      </div>
                  </div> 
              </div>
          </div>
        </div>
    </Transition>
    <Transition name="modal" v-if="showOrderTracking">
      <div  class="modal-mask">
          <div class="modal-wrapper">
              <div class="modal-container">
                  <div class="popup">
                      <div class="modal-header">
                          <slot name="header">運單追蹤 {{ displayTrackOrder.mailNo }}</slot>
                      </div>
                      <div class="modal-body">
                      <div style="height: 400px;overflow-y: auto;">
                        <table style="text-align: center;">
                          <tr>
                            <th>地址</th>
                            <th>備註</th>
                            <th>時間</th>
                          </tr>
                          <tr v-for="(record, index ) in displayTrackOrder.routes" :key="index">
                            <td>{{ record.acceptAddress }}</td>
                            <td>{{ record.remark }}</td>
                            <td>{{ record.acceptTime }}</td>
                          </tr>
                        </table>
                        </div>


                        <button class="actionButton"  @click="showOrderTracking=false">確認</button>
                      </div>
                  </div> 
              </div>
          </div>
        </div>
    </Transition>
  

    <Spinner v-if="isLoading"/>
</div>
</template>

<script>
import { 
    FormHeader,
    TextInput,
    SelectInput,
    Spinner,
    CargoList
} from "@/components";
export default {
    components: {
        FormHeader,
        TextInput,
        SelectInput,
        Spinner,
        CargoList
    },
    created() {  
        console.log(this.$route.params.orderID);
        this.getOrderDetail();
        this.setPickupTime();
    },
    methods:{
      setPickupTime: function(){
        //pick up time is current time + 1 hour
        //if current time is 2021-08-01 12:01, then pick up time is 2021-08-01 13:30
        //if current time is 2021-08-01 12:31, then pick up time is 2021-08-01 14:00
        var now = new Date();
        var hour = now.getHours();
        hour += 1;
        var minute = now.getMinutes();
        if(minute > 30){
          hour += 1;
          minute = "00";
        }else{
          minute = 30;
        }
        var newTime = now.toISOString().split("T")[0] + "T" + hour + ":" + minute;
        console.log(newTime);
        this.Request.pickupTime.value = newTime;
      },
      updateCargo: function(newCargo){
        this.Cargo = newCargo;
      },
      checkEdited: function(){
        console.log("checkEdited");
      },
      EditReceiver:function(){
        console.log("EditReceiver");
        for(const [key, value] of Object.entries(this.senderReceiverInput)){
          this.recipient[key] = value.value;
          value.value = "";
        }
        this.showEditReceiver = false;
      },
      setEditReceiver: function(){
        for(const [key, value] of Object.entries(this.senderReceiverInput)){
          value.value = this.recipient[key];
        }
        this.showEditReceiver = true;
      },
      EditSender:function(){
        for(const [key, value] of Object.entries(this.senderReceiverInput)){
          this.sender[key] = value.value;
          value.value = "";
        }
        this.showEditSender = false;
      },
      setEditSender: function(){
        for(const [key, value] of Object.entries(this.senderReceiverInput)){
          value.value = this.sender[key];
        }
        this.showEditSender = true;
      },
      cancelSFOrder: async function(){
        this.isLoading = true;
        try {
          const response = await this.$store.dispatch('cancelSFOrder',{router:this.router,data:{DeliveryOrderID : this.DeliveryOrderID }})
          console.log(response.data);
          this.isLoading = false;
          this.DeliveryOrder = [];
          this.getOrderDetail();
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
      getDeliveryOrder: async function(){
        this.isLoading = true;
        try {
          const response = await this.$store.dispatch('getDeliveryOrder',{router:this.router,data:{DeliveryOrderID: this.DeliveryOrderID}})
          console.log(response.data);
          this.Cargo = response.data.DeliveryOrder.cargoList;
          this.DeliveryOrder = response.data.DeliveryOrder.waybillNoList
          this.recipient.contact = response.data.DeliveryOrder.recipient.contact
          this.recipient.mobile = response.data.DeliveryOrder.recipient.mobile
          this.recipient.country = response.data.DeliveryOrder.recipient.country
          this.recipient.city = response.data.DeliveryOrder.recipient.city
          this.recipient.county = response.data.DeliveryOrder.recipient.county
          this.recipient.address = response.data.DeliveryOrder.recipient.address
          this.sender.contact = response.data.DeliveryOrder.sender.contact
          this.sender.mobile = response.data.DeliveryOrder.sender.mobile
          this.sender.country = response.data.DeliveryOrder.sender.country
          this.sender.city = response.data.DeliveryOrder.sender.city
          this.sender.county = response.data.DeliveryOrder.sender.county
          this.sender.address = response.data.DeliveryOrder.sender.address
          this.isLoading = false;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
      getOrderDetail: async function(){
          this.isLoading = true;
          try {
              const response = await this.$store.dispatch('getOrderDetail',{router:this.router,data:{OrderID: this.orderID}})
              console.log(response.data);
              this.orderDetail = response.data.order;
              this.user = response.data.user;
              this.setCargo(this.orderDetail.Product);
              if(this.orderDetail.Status === "IN PROCESS"){
                this.canEdit = true;
                this.setRecipient(this.orderDetail.DeliveryAddress);
                
                this.formActionButton = "Create Delivery";
              }else if(this.orderDetail.Status === "Shipped"){
                this.DeliveryOrderID = this.orderDetail.DeliveryOrderID;
                this.getDeliveryOrder();
                this.trackOrder();
              }
          } catch (error) {
              console.log(error);
          } finally {
              this.isLoading = false;
          }
      },
      setRecipient: function(DeliveryAddress){
        this.recipient.contact = DeliveryAddress.family_name + " " + DeliveryAddress.given_name;
        this.recipient.mobile = DeliveryAddress.phone;
        this.recipient.country = DeliveryAddress.country;
        this.recipient.city = DeliveryAddress.city;
        this.recipient.county = DeliveryAddress.district;
        this.recipient.address = DeliveryAddress.addressOne
        if(DeliveryAddress.addressTwo){
          this.recipient.address += ", " + DeliveryAddress.addressTwo;
        }
      },
      setCargo: function(Product){
        this.Cargo = [];
        var Item = [];
        var now = new Date().getTime();
        for(var i in Product){
          const product = Product[i];
          for(var v in product.VarianceList){
            const variance = product.VarianceList[v];
            var name = product.ProductType.Name[this.language]
            if(product.ProductType.Name.en == "Tea"){
              if(this.language == "cn"){
                name = "茶叶";
              }else if(this.language == "zh"){
                name = "茶葉";
              }
            }
            Item.push({
              "itemID": variance.VarianceID,
              "image": this.s3path+variance.Image[0].path,
              "name": name,
              "productName": product.Name[this.language] + "(" + variance.Name[this.language] + ")",
              "count": variance.SelectedQuantity,
              "amount": variance.Price,
              "weight": variance.Weight,
              "currency": "HKD",
              "unit": "盒",
              "sourceArea": product.Country.Alpha2,
            })
          }
        }
        this.Cargo.push({
          "cargoID": "c"+now,
          "name": "Cargo 1",
          "Item": Item,
          "totalWeight": Item.reduce((a, b) => a + b.weight, 0),
          "minHeight": 240 * Item.length,
        });
      },
      formAction: function(){
          this.showRequestPopup = true;
      },
      calculateSF: async function(){
        var totalWeight = this.Cargo.reduce((a, b) => a + b.totalWeight, 0);
        var object = {
          "totalWeight": totalWeight,
          "sender": this.sender,
          "recipient": this.recipient,
          "expressType": parseInt(this.Request.expressType.value),
        }
        console.log(object);
        this.isLoading = true;
        try {
          const response = await this.$store.dispatch('calculateSF',{router:this.router,data:object})
          console.log(response.data);
          if(response.data.result){
            this.fee = response.data.apiResultData.msgData.deliverTmDto[0].fee
            this.showfee = true;
          } 
          this.isLoading = false;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
      createSFOrder: async function(){
        var totalWeight = this.Cargo.reduce((a, b) => a + b.totalWeight, 0);
        var object = {
          orderID: this.orderID,
          expressType: this.Request.expressType.value,
          date: this.Request.pickupTime.value.split("T")[0],
          time:this.Request.pickupTime.value.split("T")[1],
          totalWeight: totalWeight,
          remark: this.Request.remark.value,
          sender: this.sender,
          recipient: this.recipient,
          cargoList: this.Cargo
        }
        this.isLoading = true;
        try {
          const response = await this.$store.dispatch('createSFOrder',{router:this.router,data:object})
          console.log(response.data);
          this.isLoading = false;
          await this.createOrderPDF();
          this.showRequestPopup = false;
          this.getOrderDetail();
          //this.$router.push({name: "Payment"});
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
        console.log(object);
      },

      downloadPDF: async function(id){
        console.log(id);
        console.log(this.DeliveryPDF.length);
        if(this.DeliveryPDF.length == 0){
          await this.createOrderPDF();
        }
        console.log(this.DeliveryPDF);
        const pdf = this.DeliveryPDF.find(pdf => pdf.waybillNo == id);
        console.log(pdf.downloadLink);
        //open url in new tab
        window.open(pdf.downloadLink, '_blank');
      },
      openTrackOrderPopup: function(id){
        this.displayTrackOrder = this.trackOrderList.find(order => order.mailNo == id);
        this.showOrderTracking = true;
      },
      trackOrder: async function(){
        console.log("trackOrder");
        this.isLoading = true;
        try{
          const response = await this.$store.dispatch('trackOrder',{router:this.router,data:{DeliveryOrderID: this.DeliveryOrderID}})
          console.log(response.data);
          this.trackOrderList = response.data.trackingResult.routeResps
          this.isLoading = false;
        }catch(error){
          console.log(error);
        }finally{
          this.isLoading = false;
        }
      },
      createOrderPDF: async function(){
        this.isLoading = true;
        try {
          const response = await this.$store.dispatch('createOrderPDF',{router:this.router,data:{DeliveryOrderID: this.DeliveryOrderID}})
          console.log(response.data);
          await this.savePDF(response.data.files);
          this.isLoading = false;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
      savePDF: async function(files){
        console.log("savePDF");
        for(var i in files){
          const file = files[i];
          var object = {
            "DeliveryOrderID": this.DeliveryOrderID,
            "orderID": this.orderID,
            "waybillNo": file.waybillNo,
            "url": file.url,
            "token": file.token,
          }
          try {
            const response = await this.$store.dispatch('downloadSFpdf',{router:this.router,data:object})
            console.log(response.data);
            this.DeliveryPDF.push(response.data.pdf);
          } catch (error) {
            console.log(error);
          }
        }
      }
      
    },
    data() {
        return {
          formActionButton: "",
          senderReceiverInput:{
            contact: {
              "key": "contact",
              "name": "聯絡人",
              "required": true,
              "value": "",
              "type": "text"
            },
            company: {
              "key": "company",
              "name": "公司",
              "required": false,
              "value": "",
              "type": "text"
            },
            mobile: {
              "key": "mobile",
              "name": "電話",
              "required": true,
              "value": "",
              "type": "text"
            },
            country: {
              "key": "country",
              "name": "國家",
              "required": true,
              "value": "",
              "type": "text"
            },
            city: {
              "key": "city",
              "name": "城市",
              "required": true,
              "value": "",
              "type": "text"
            },
            county: {
              "key": "county",
              "name": "縣/區",
              "required": true,
              "value": "",
              "type": "text"
            },
            province:{
              "key": "province",
              "name": "省",
              "required": false,
              "value": "",
              "type": "text"
            },
            address: {
              "key": "address",
              "name": "地址",
              "required": true,
              "value": "",
              "type": "text"
            }
          },
          showEditReceiver: false,
          showEditSender: false,
          showOrderTracking: false,
          icon: "icon",
          content: "content",
          s3path: process.env.VUE_APP_S3_URL,
          isLoading: false,
          orderDetail: {},
          language: "zh",
          user: {},
          DeliveryPDF: [],
          DeliveryOrderID: "",
          DeliveryOrder: [],
          trackOrderList: [],
          displayTrackOrder: {},
          canEdit: false,
          showfee: false,
          fee: 0,
          sender: {
            "contactType": "1",
            "contact": "童小姐",
            "company": "一杯子（深圳）国际控股有限公司",
            "mobile": "+8618680769040",
            "country": "中国",
            "city": "深圳市",
            "county": "龙华区",
            "province": "广东省",
            "address": "广东省深圳市龙华区星河world2期E栋22层EC32"
          },
          recipient:{
            "contactType": "2",
            "contact": "",
            "mobile": "",
            "country": "",
            "city": "",
            "county": "",
            "province": "",
            "address": ""
          },
          orderID: this.$route.params.orderID,
          showRequestPopup: false,
          Request: {
            expressType:{
              "key": "expressType",
              "name": "快遞服務",
              "required": true,
              "value": "6",
              "option": [
                  {
                  "key": "1",
                  "name": "顺丰特快",
                  },
                  // {
                  // "key": "2",
                  // "name": "顺丰特惠",
                  // },
                  // {
                  // "key": "5",
                  // "name": "顺丰次晨",
                  // },
                  // {
                  // "key": "6",
                  // "name": "順豐即日",
                  // },
                  // {
                  // "key": "29",
                  // "name": "國際電商專遞-標準",
                  // },
                  // {
                  // "key": "132",
                  // "name": "順豐國際特惠(FBA)",
                  // },
                  // {
                  // "key": "276",
                  // "name": "酒類溫控上門",
                  // },
                  // {
                  // "key": "24",
                  // "name": "顺丰国际特惠(包裹)",
                  // },
                  
              ],
            },
            pickupTime:{
              key: "pickupTime",
              name: "取件時間",
              required: true,
              value: null,
              type: "datetime-local"
            },
            remark:{
              key: "remark",
              name: "備註",
              required: false,
              value: "",
              type: "text"
            }
            
          },
          Cargo: []
      }
    },
}
</script>
<style>
.editButton{
  margin: auto;
  margin-right: 0;
  cursor: pointer;
}
.download{
  cursor: pointer;
}
</style>
