<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }" >

    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >

      <mobile-menu slot="content"></mobile-menu>
      <!-- <sidebar-link to="/dashboard">
        <md-icon>dashboard</md-icon>
        <p>Dashboard</p>
      </sidebar-link> -->
      <sidebar-link to="/customer">
        <p>Customer List</p>
      </sidebar-link>
      <sidebar-link to="/customergroup">
        <!-- <md-icon>account_circle</md-icon> -->
        <p>Customer Group</p>
      </sidebar-link>
      <sidebar-link to="/product">
        <!-- <md-icon>store</md-icon> -->
        <p>Product List</p>
      </sidebar-link>
      <sidebar-link to="/coupon">
        <!-- <md-icon>local_atm</md-icon> -->
        <p>Coupon List</p>
      </sidebar-link>
      <sidebar-link to="/location">
        <!-- <md-icon>restaurant</md-icon> -->
        <p>Location List</p>
      </sidebar-link>
      <sidebar-link to="/eventlist">
        <!-- <md-icon>celebration</md-icon> -->
        <p>Event List</p>
      </sidebar-link>
      <sidebar-link to="/payments">
        <!-- <md-icon>paid</md-icon> -->
        <p>Payments Management</p>
      </sidebar-link>
      <sidebar-link to="/refundrequest">
        <!-- <md-icon>paid</md-icon> -->
        <p>Refund Request</p>
      </sidebar-link>
      <!-- <sidebar-link to="/qr-scanner">
        <md-icon>qr_code_scanner</md-icon>
        <p>QR Code Scanner</p>
      </sidebar-link> -->
      <sidebar-link to="/surveytemplate">
        <!-- <md-icon>content_paste</md-icon> -->
        <p>Survey Template</p>
      </sidebar-link>
      <sidebar-link to="/ContentManagement">
        <!-- <md-icon>view_quilt</md-icon> -->
        <p>Content Management</p>
      </sidebar-link>
      <sidebar-link to="/EmailEvent">
        <!-- <md-icon>alternate_email</md-icon> -->
        <p>Email Event</p>
      </sidebar-link>
      <sidebar-link to="/EmailTemplate">
        <!-- <md-icon>email</md-icon> -->
        <p>Email Template</p>
      </sidebar-link>
      <sidebar-link to="/InspirationList">
        <!-- <md-icon>add_comment</md-icon> -->
        <p>Inspiration</p>
      </sidebar-link>
      <sidebar-link to="/Inventory">
        <!-- <md-icon>account_balance</md-icon> -->
        <p>Inventory</p>
      </sidebar-link>
      <sidebar-link to="/TestPayment">
        <!-- <md-icon>account_balance</md-icon> -->
        <p>Test Payment</p>
      </sidebar-link>
      <sidebar-link to="/Logout">
        <!-- <md-icon>account_balance</md-icon> -->
        <p>Logout</p>
      </sidebar-link>
      <!-- <sidebar-link to="/login">
        <p>Logout</p>
      </sidebar-link> -->
      <!-- <sidebar-link to="/table">
        <md-icon>content_paste</md-icon>
        <p>Table list</p>
      </sidebar-link>
      <sidebar-link to="/typography">
        <md-icon>library_books</md-icon>
        <p>Typography</p>
      </sidebar-link>
      <sidebar-link to="/icons">
        <md-icon>bubble_chart</md-icon>
        <p>Icons</p>
      </sidebar-link>
      <sidebar-link to="/maps">
        <md-icon>location_on</md-icon>
        <p>Maps</p>
      </sidebar-link>
      <sidebar-link to="/notifications">
        <md-icon>notifications</md-icon>
        <p>Notifications</p>
      </sidebar-link> -->
    </side-bar>
    <div class="main-panel">

      <fixed-plugin
        :color.sync="sidebarBackground"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin>

      <dashboard-content> </dashboard-content>

      <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
    </div>
  </div>
</template>

<script>

//import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "@/pages/Layout/MobileMenu.vue";
import FixedPlugin from "./Extra/FixedPlugin.vue";

export default {
  components: {

    DashboardContent,
    //ContentFooter,
    MobileMenu,
    FixedPlugin
  },
  data() {
    return {
      sidebarBackground: "black",
      sidebarBackgroundImage: null
    };
  }
};
</script>

<style>

</style>