<template>
    <div>
        <h3>Cargo List</h3>
       
        <div v-for="cargo in cargos" :key="cargo.cargoID">
            <div class="collapsible">
                <input type="checkbox" style="display:none" :id=cargo.cargoID checked/>
                <div class="row unsetMargin" @click="expandItem(cargo.cargoID)">
                    <div class="explorIcon" > 
                        <md-icon :id="cargo.cargoID+icon" style="color: white;">expand_less</md-icon>
                    </div>
                    <div class="col explorTitle"><label>{{ cargo.name }} (總重量: {{ cargo.totalWeight }}g)</label></div>
                </div>
                <div class="collapsible-item" :id="cargo.cargoID+content" :style="setMinHeight(cargo.minHeight)">
                    <div class="row" v-for="item in cargo.Item" :key="item.itemID">
                        <div class="column" style="padding:20px">
                            <img class="displayImage" :src=item.image>
                        </div>
                        <div class="col displayItem" style="padding:20px">
                            <label>報關名稱: {{ item.name }}</label><br>
                            <label>產品名稱: {{ item.productName }}</label><br>
                            <label>{{ item.weight }}g x {{ item.count }}{{ item.unit }}</label><br>
                            <label>總價: {{ item.currency }} ${{ item.amount * item.count }}</label> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button v-if='canEdit' @click="goMangeCargo()" class="newProductButton">Manage</button>

        <Transition name="modal" v-if="showMangeCargo">
            <div  class="modal-mask" style="overflow: hidden;">
                <div class="modal-wrapper">
                    <div class="product-select-container">
                        <div class="popup">
                            <div class="row" style="padding: 0 10px">
                                <md-button class="backbutton" style="background-color:white !important;" @click="showMangeCargo=false">
                                    <md-icon class="backIcon" style="color:black !important;margin-left: -10px;">arrow_back_ios</md-icon>
                                </md-button>
                                <h3 class="title" style="flex: 1; padding-left: 20px;padding-top: 18px;">Manage Cargo</h3>
                                <button  class="tablenewItemButton" @click="saveCargo()">Save</button>
                                <md-card>
                                    <div class="row">
                                        <md-card-content style="width: 50%;min-height:700px; overflow-y: auto;">
                                            <h3>Manage Cargo</h3>
                                                <draggable
                                                    class="dragArea"
                                                    tag="ul"
                                                    :list="pendingItem"
                                                    :group="{ name: 'cargoItem' }" 
                                                    style="height:100%;width: 95%;overflow-y: hidden;max-height: 500px;"

                                                >
                                                    <div class="row" v-for="item in pendingItem" :key="item.itemID" style="width: 80%;">
                                                        <div class="column" style="padding:20px" >
                                                            <img class="displayImage" :src=item.image>
                                                        </div>
                                                        <div class="col displayItem" style="padding:20px">
                                                            <label>報關名稱: {{ item.name }}</label><br>
                                                            <label>產品名稱:  {{ item.productName }}</label><br>
                                                            <label>{{ item.weight }}g x {{ item.count }}{{ item.unit }}</label><br>
                                                            <label>總價: {{ item.currency }} ${{ item.amount * item.count }}</label><br>
                                                            <div v-if="item.deletable">
                                                                <a class="remove" @click="removeItem('pendingItem',item)">remove</a>
                                                            </div>
                                                            <div v-else>
                                                                <a class="remove" @click="setEditItem('pendingItem',item)">edit</a>
                                                            </div>
                                                        </div>  
                                                    </div>
                                                        
                                                </draggable>
                                                <md-card-content >
                                                    <div style="text-align: center;" @click="showAddItem=true">
                                                        <md-icon>add_circle_outline</md-icon> <u class="addQuestion">Add new item</u>
                                                    </div>
                                                </md-card-content>
                                        </md-card-content>
                                        <md-card-content style="width: 50%;max-height: 700px;min-height:500px; overflow-y: auto;">
                                            <div v-for="cargo in EditedCargoList" :key="cargo.cargoID">
                                                    <div class="collapsible">
                                                        <input type="checkbox" style="display:none" :id=cargo.cargoID+popup checked/>
                                                        <div class="row unsetMargin" >
                                                            <div class="row" @click="expandItem(cargo.cargoID+popup)" style="width: 95%;">
                                                                <div class="explorIcon" > 
                                                                    <md-icon :id="cargo.cargoID+popup+icon" style="color: white;">expand_less</md-icon>
                                                                </div>
                                                                <div class="col explorTitle"><label>{{ cargo.name }} (總重量: {{ cargo.totalWeight }}g)</label></div>
                                                            </div>
                                                            <div class="explorFunction"><label><u>edit</u></label></div>
                                                        </div>
                                                        <div class="collapsible-item" :id="cargo.cargoID+popup+content" :style="setMinHeight(cargo.minHeight)">
                                                            <draggable
                                                                class="dragArea"
                                                                tag="ul"
                                                                :list="cargo.Item"
                                                                :group="{ name: 'cargoItem' }"
                                                                style="min-height:140px;width: 95%;"  
                                                            >
                                                            <center>
                                                                <div class="row" v-for="item in cargo.Item" :key="item.itemID">
                                                                    <div class="column" style="padding:20px">
                                                                        <img class="displayImage" :src=item.image>
                                                                    </div>
                                                                    <div class="col displayItem" style="padding:20px">
                                                                        <label>報關名稱: {{ item.name }}</label><br>
                                                                        <label>產品名稱: {{ item.productName }}</label><br>
                                                                        <label>{{ item.weight }}g x {{ item.count }}{{ item.unit }}</label><br>
                                                                        <label>總價: {{ item.currency }} ${{ item.amount * item.count }}</label> 
                                                                    </div>
                                                                </div>
                                                            </center>

                                                            </draggable>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                <md-card-content>
                                                    <div style="text-align: center;" @click="addCargo()">
                                                        <md-icon>add_circle_outline</md-icon> <u class="addQuestion">Add new cargo</u>
                                                    </div>
                                                </md-card-content>
                                        </md-card-content>
                                    </div>
                                </md-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    <Transition name="modal" v-if="showEditCargo">
      <div  class="modal-mask">
          <div class="modal-wrapper">
              <div class="modal-container">
                  <div class="popup">
                      <div class="modal-header">
                          <slot name="header">新增貨品</slot>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col" style="width: 50%;">
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.name"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.count"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.weight"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.unit"
                            />
                          </div>
                          <div class="col" style="width: 50%;">
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.productName"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.amount"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.currency"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.sourceArea"
                            />
                          </div>
                        </div>
                          <div class="row" style="text-align: center;padding-top: 20px;">
                              <div class="column" style="width:47.5%">
                                  <button class="cancelButton" @click="showAddItem=false">取消</button>
                              </div>
                              <div class="column" style="width:5%"></div>
                              <div class="column" style="width:47.5%">
                                  <button class="actionButton"  @click="addItem()">確認</button>
                              </div>
                          </div>
                      </div>
                  </div> 
              </div>
          </div>
        </div>
    </Transition>
    <Transition name="modal" v-if="showAddItem">
      <div  class="modal-mask">
          <div class="modal-wrapper">
              <div class="modal-container">
                  <div class="popup">
                      <div class="modal-header">
                          <slot name="header">新增貨品</slot>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col" style="width: 50%;">
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.name"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.count"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.weight"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.unit"
                            />
                          </div>
                          <div class="col" style="width: 50%;">
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.productName"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.amount"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.currency"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.sourceArea"
                            />
                          </div>
                        </div>
                          <div class="row" style="text-align: center;padding-top: 20px;">
                              <div class="column" style="width:47.5%">
                                  <button class="cancelButton" @click="showAddItem=false">取消</button>
                              </div>
                              <div class="column" style="width:5%"></div>
                              <div class="column" style="width:47.5%">
                                  <button class="actionButton"  @click="addItem()">確認</button>
                              </div>
                          </div>
                      </div>
                  </div> 
              </div>
          </div>
        </div>
    </Transition>
    <Transition name="modal" v-if="showEditItem">
      <div  class="modal-mask">
          <div class="modal-wrapper">
              <div class="modal-container">
                  <div class="popup">
                      <div class="modal-header">
                          <slot name="header">修改貨品</slot>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col" style="width: 50%;">
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.name"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.count"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.weight"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.unit"
                            />
                          </div>
                          <div class="col" style="width: 50%;">
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.productName"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.amount"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.currency"
                            />
                            <TextInput
                              v-on:checkEdited="checkEdited"
                              :Item="newItemInput.sourceArea"
                            />
                          </div>
                        </div>
                          <div class="row" style="text-align: center;padding-top: 20px;">
                              <div class="column" style="width:47.5%">
                                  <button class="cancelButton" @click="showEditItem=false">取消</button>
                              </div>
                              <div class="column" style="width:5%"></div>
                              <div class="column" style="width:47.5%">
                                  <button class="actionButton"  @click="editItem()">確認</button>
                              </div>
                          </div>
                      </div>
                  </div> 
              </div>
          </div>
        </div>
    </Transition>
    <md-snackbar :md-active.sync="showMessage">{{Message}}</md-snackbar>             
    </div>
</template>
<script>
import draggable from "vuedraggable";
import { 
    TextInput,
} from "@/components";
export default {
    components: {
        draggable,
        TextInput
    },
    props: {
        cargos: {
            type: Array,
            required: true,
            default() {
                return [];
            },
        },
        canEdit: {
            type: Boolean,
            required: true,
            default() {
                return false;
            },
        },
    },
    data() {
        return {
            icon: "icon",
            content: "content",
            popup: "popup",
            showEditCargo: false,
            showEditItem: false,
            showMangeCargo: false,
            showAddItem: false,
            showMessage: false,
            Message: "",
            EditedCargoList: [],
            pendingItem: [],
            newItemInput:{
                name: {
                    "key": "name",
                    "name": "報關名稱",
                    "required": true,
                    "value": "",
                    "type": "text"
                },
                productName:{
                    "key": "productName",
                    "name": "產品名稱",
                    "required": true,
                    "value": "",
                    "type": "text"
                },
                count:{
                    "key": "count",
                    "name": "數量",
                    "required": true,
                    "value": "",
                    "type": "number"
                },
                amount:{
                    "key": "amount",
                    "name": "單價",
                    "required": true,
                    "value": "",
                    "type": "number"
                },
                weight:{
                    "key": "weight",
                    "name": "重量(g)",
                    "required": true,
                    "value": "",
                    "type": "number"
                },
                currency:{
                    "key": "currency",
                    "name": "貨幣",
                    "required": true,
                    "value": "HKD",
                    "type": "text"
                },
                unit:{
                    "key": "unit",
                    "name": "單位",
                    "required": true,
                    "value": "盒",
                    "type": "text"
                },
                sourceArea:{
                    "key": "sourceArea",
                    "name": "產地",
                    "required": true,
                    "value": "",
                    "type": "text"
                },
            },
            editedItem: {}
        };
    },
    methods: {
        setMinHeight:function(minHeight){
            if(minHeight==0){
                minHeight = "140px";
            }else{
                minHeight = minHeight+"px";
            }

            return {
                "max-Height": minHeight,
                "min-Height": minHeight
            }
        },
        expandItem:function(element){
            let iconClass = document.getElementById(element+this.icon)
            let contentClass = document.getElementById(element+this.content)
            console.log("iconClass", iconClass)
            if(document.getElementById(element).checked){
                iconClass.classList.remove('iconup')
                iconClass.classList.add('icondown')
            }else{
                iconClass.classList.remove('icondown')
                iconClass.classList.add('iconup')
            }
            document.getElementById(element).checked = !document.getElementById(element).checked;
            if(document.getElementById(element).checked){
                console.log("scrollHeight", contentClass.scrollHeight)
                contentClass.style.minHeight = contentClass.scrollHeight + "px";
                contentClass.style.maxHeight = contentClass.scrollHeight + "px";
            }else{
                contentClass.style.maxHeight = "0px";
                contentClass.style.minHeight = "0px";
            }      
        },
        checkEdited: function () {
            console.log("checkEdited");
        },
        saveCargo:function(){
            console.log("cargos", this.cargos)
            console.log("EditedCargoList", this.EditedCargoList)
            console.log("pendingItem", this.pendingItem)
            if(this.pendingItem.length>0){
                this.Message = "Please move all the item to the cargo list";
                this.showMessage = true;
            }else{
                this.$emit("updateCargo", this.EditedCargoList);
                this.showMangeCargo = false;
            }
            
        },
        goMangeCargo: function(){
            //duplicate the cargo list to edited cargo list
            this.pendingItem = [];
            this.EditedCargoList = JSON.parse(JSON.stringify(this.cargos));
            console.log("goMangeCargo", this.EditedCargoList)
            this.showMangeCargo = true;
        },
        addCargo:function(){
            this.EditedCargoList.push({
                "cargoID": this.EditedCargoList.length,
                "name": "New Cargo",
                "totalWeight": 0,
                "Item": [],
                "minHeight": 140
            });
        },
        addItem: function(){
            var now = new Date();
            this.pendingItem.push(
                {
                    "itemID": now.getTime(),
                    "name": this.newItemInput.name.value,
                    "productName": this.newItemInput.productName.value,
                    "count": this.newItemInput.count.value,
                    "amount": this.newItemInput.amount.value,
                    "weight": this.newItemInput.weight.value,
                    "currency": this.newItemInput.currency.value,
                    "unit": this.newItemInput.unit.value,
                    "sourceArea": this.newItemInput.sourceArea.value,
                    "image": "",
                    "deletable": true
                })
            this.showAddItem = false;
        },
        removeItem: function(list, item){
            console.log("removeItem", list, item)
            if(list=="pendingItem"){
                this.pendingItem = this.pendingItem.filter(function(value, index, arr){
                    return value.itemID != item.itemID;
                });
            }else{
                for(var i=0; i<this.EditedCargoList.length; i++){
                    this.EditedCargoList[i].Item = this.EditedCargoList[i].Item.filter(function(value, index, arr){
                        return value.itemID != item.itemID;
                    });
                }
            }
        },
        editItem: function(){
            for(const [key, value] of Object.entries(this.newItemInput)){
                this.editedItem[key] = value.value;
                value.value = "";
            }
            var index = this.pendingItem.findIndex(x => x.itemID === this.editedItem.itemID);
            console.log("index", index)
            this.pendingItem[index] = this.editedItem;
            this.editedItem = {};
            this.showEditItem = false;
        },
        setEditItem: function(list, item){
            this.editedItem = item;
            console.log("editItem", list, item)
            this.newItemInput.name.value = item.name;
            this.newItemInput.productName.value = item.productName;
            this.newItemInput.count.value = item.count;
            this.newItemInput.amount.value = item.amount;
            this.newItemInput.weight.value = item.weight;
            this.newItemInput.currency.value = item.currency;
            this.newItemInput.unit.value = item.unit;
            this.newItemInput.sourceArea.value = item.sourceArea;
           
            this.showEditItem = true;
        }
    },
    watch: {
        EditedCargoList: {
            handler: function (val) {
                //update the totalWeight of cargo
                for (var i = 0; i < val.length; i++) {
                    var totalWeight = 0;
                    var newMinHeight = 0;
                    if(val[i].Item.length>0){
                        for (var j = 0; j < val[i].Item.length; j++) {
                            totalWeight += val[i].Item[j].weight * val[i].Item[j].count;
                            newMinHeight += 240;
                        }
                    }else{
                        newMinHeight = 140;
                    }

                    val[i].totalWeight = totalWeight;
                    val[i].minHeight = newMinHeight;
                }
            },
            deep: true
        },
        showMangeCargo: function (val) {
            //if true, add over overflow-y: hidden to body
            console.log("showEditCargo", val)
            if (val) {
                
                document.body.style.overflowY = "hidden";
            } else {
                document.body.style.overflowY = "auto";
            }
        }
    }
}
</script>
<style scoped>
.explorFunction{
    margin: auto;
    color: white;
    padding-right: 10px;
    width: 5%;
}
  .itemRemove{
      font-weight: 400;
      font-size: 18px;
      padding-top:25px;
      color:#CC2727;
      cursor: pointer;
  }
  .imagePreview{
      font-weight: 400;
      font-size: 18px;
      padding-top:25px;
      cursor: pointer;
  }
.dragArea {
  min-height: 0px;
  outline: 1px dashed;
}
.nav-card {
  background-color: transparent !important;
  box-shadow: none;
  margin: 0;
}
.nav-card .md-card-actions {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent !important;
}

.nav-card .md-toolbar {
  display: flex;
  margin: 0;
}
.nav-card .md-card-expand-content {
  background-color: #fff;
}
.nav-card .md-toolbar-section-start {
  display: flex;
}

.nav-card .md-toolbar-section-start .md-button{
  box-shadow: none;
}

.nav-card .md-menu .md-button{
  box-shadow: none;
}
.DragDropTable tr td{
  border: none;
}
.addLang{
      cursor: pointer;
      font-size: 18px;
  }
.formInput{
  font-weight: 500;
  font-size: 18px !important;
}
.expandForm{
    width: 95%;
    margin: auto;
    margin-right: 0;
    padding-top: 20px;
}
.expandForm label{
    font-size: 18px;
    font-weight: 500;
}
</style>
