<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
      <TableHeader
        :HeaderTitle="HeaderTitle"
        :AddNewButton="AddNewButton"
        v-on:createNewItem="createNewItem"
      ></TableHeader>
      <md-card>
          <md-card-content>
            <TableComponent 
            v-on:selectItem="selectItem"
            v-on:selectAllItem="selectAllItem"
            v-on:selectPageItem="selectPageItem"
            v-on:searchItem="searchItem"
            v-on:onclickItem="onclickItem"
            v-on:selectSortOption="selectSortOption"
            v-on:selectedItemActionFunction="selectedItemActionFunction"
            v-on:goPage="goPage"
            v-on:setFilter="setFilter"
            v-on:SortPopupControl="SortPopupControl"
            :TableList="TableItemList"
            :selectedItemCounter="selectedItemCounter"
            :selectedItem="selectedItem"
            :columns="columns"
            :selectAll="selectAll"
            :selectable="selectable"
            :haveSearchFunction="haveSearchFunction"
            :sortOption="sortOption"
            :selectedItemAction="selectedItemAction"
            :pageNumber="pageNumber"
            :showNextButton="showNextButton"
            :showSortPopup="showSortPopup"
            :filterOption="filterOption"
            ></TableComponent>
          </md-card-content>
        </md-card>
        <!-- :AddNewItem="AddNewItem" -->
      </div>
      <Transition name="modal" v-if="showUpdateStatusPopup">
          <Popup
            v-on:popupcancel="popupcancel"
            v-on:popupaction="updateEventStatus"
            PopupTitle="更新活動"
            :PopupContent="'是否確定將選擇的活動狀態更新為' + newEventStatus.Name + '?'"
            actionButton="確定"
          ></Popup>
      </Transition>
    </div>
    <Spinner v-if="isLoading"/> 
    <md-snackbar :md-active.sync="showMessage">{{message}}</md-snackbar>
    
  </div>
</template>

<script>
import { 
  TableComponent,
  TableHeader,
  Popup,
  Spinner
} from "@/components";

export default {
  components: {
    TableComponent,
    TableHeader,
    Popup,
    Spinner
  },
  data() {
    return {
      isLoading: false,
      message: "",
      showSortPopup: false,
      showUpdateStatusPopup: false,
      newEventStatus: {
        Name: "",
        Key: ""
      },
      index: 0,
      pageNumber: 1,
      showMessage: false,
      showNextButton: true,
      displaySize: 10,
      HeaderTitle: "Event List",
      TableItemList: [],
      AddNewButton:  "Add Event",
      columns: ["Event Name", "Type", "Level", "Status"],
      Source: ["EventID", "Title", "EventType.Name", "EventLevel.Name", "Status"],
      Sort: {"CreatedDatetime": "desc"},
      //sortOption:[],
      sortOption: [
        {Key: "CreatedDatetime", Name: "Recent Added", Value: true, Order: "desc"}
      ],
      selectedItemAction: [
        {Key: "Hide", Name: "隱藏"},
        {Key: "Private", Name: "私人"},
        {Key: "UnListed", Name: "不公開"},
        {Key: "Published", Name: "公開"},
      ],
      filterOption:[
          {
            Key: "EventType", 
            Name: "Type", 
            type: "checkbox",
            inputType: "checkbox",
            option:[]
          },
          {
            Key: "EventLevel", 
            Name: "Level", 
            type: "checkbox",
            inputType: "checkbox",
            option:[]
          },
        ],
      selectedItem: [],
      selectedItemCounter: 0,
      selectAll: false,
      selectable: true,
      haveSearchFunction: true,
      filter: {},
      range: {},
    }
  },
  async created() {
      this.isLoading = true;
      this.message = this.$route.params.message;
      if(this.$route.params.page){
        if(Number(this.$route.params.page) > 0){
          this.pageNumber = Number(this.$route.params.page)
        }
        this.index = this.index + (Number(this.$route.params.page) - 1 )*this.displaySize
      }
      this.checkShowMessage();
      this.getEventType();
      await this.getEventLevel();
      await this.searchItem({keyword:""});
      this.isLoading = false;
    },
  methods:{
    SortPopupControl: function (){
        this.showSortPopup = !this.showSortPopup
      },
    goPage:function(action){
      if(action == "next"){
        this.index = this.index + this.displaySize
        this.pageNumber = this.pageNumber + 1
      }else if(action == "prev" && this.index > 0){
        this.index = this.index - this.displaySize
        this.pageNumber = this.pageNumber - 1
      }else{
        this.index = 0
        this.pageNumber = 1
      }
      this.$router.replace({ name: "Event List PageNumber", params: {page:this.pageNumber} })
      this.searchItem({keyword:""})
    },
    checkShowMessage: async function(){
      if(this.message){
        try{
          await this.delayFunction();
          this.showMessage = true;
        }catch(err){
          console.log(err)
        }
      }
    },
    delayFunction:async function (){
        setTimeout(function(){
          this.showMessage = false;
          this.message = "";
          return true;
        }, 3000);
    },
    selectedItemActionFunction:function(action){
      console.log("selectedItemActionFunction", action);
      this.newEventStatus = this.selectedItemAction.find(x => x.Key == action)
      this.showUpdateStatusPopup = true
    },
    onclickItem: function(ID){
      console.log("onclickItem", ID);
      this.$router.push({name: 'Update Event', params:{eventID:ID}})
    },
    createNewItem: function(){
      this.$router.push({name: 'Create Event'})
    },
    popupcancel: function(){
      this.showUpdateStatusPopup = false;
    },
    selectSortOption: function(option){
      console.log("charge sorting with " + option);
      var sortOrder = "";
      var index = this.sortOption.findIndex(sort => sort.Key == option)
      console.log("option", index);
      if(index > -1){
        this.sortOption[index].Value = true
        this.sortOption[index].Order = this.sortOption[index].Order == "asc" ? "desc" : "asc"

        sortOrder = this.sortOption[index].Order
        this.Sort[option] = sortOrder

        this.showSortPopup = false;
        this.searchItem({keyword:""});
      }
    },
    selectPageItem:async function(action){
      console.log("selectPageItem", action)
      console.log(this.TableItemList)
      
      if(action){
        for(var i in this.TableItemList){
          var index = this.selectedItem.indexOf(this.TableItemList[i].ID)
          if(index == -1){
            this.selectedItem.push(this.TableItemList[i].ID)
          }
        }
      }else{
        for(var i in this.TableItemList){
          var index = this.selectedItem.indexOf(this.TableItemList[i].ID)
          if(index > -1){
            this.selectedItem.splice(index, 1)
          }
        }
      }
      this.selectedItemCounter = this.selectedItem.length
    },
    selectAllItem: async function(action, {keyword}){
      console.log("action", action);
      console.log("keyword", keyword);
        if(!action){
          this.selectAll = true;
          this.isLoading = true;
          var object = {
            "Source": this.Source,
            "Filter": this.filter,
            "Range": this.range
          }
          if(keyword){
            object["Keyword"] = keyword
          }
            try{
              const ret= await this.$store.dispatch('searchEvent',{router:this.router,keyword:object})
              this.selectedItem = ret.data.data.hits.hits.map(item => String(item._id))
              this.isLoading = false;
            }catch(err){
              
              this.formMessage = err.response.data.message
              this.showFormMessage = true
            } 
        }else{
          this.selectAll = false;
          this.selectedItem = []
        }
        console.log("after", this.selectedItem);
        this.selectedItemCounter = this.selectedItem.length
      },
    selectItem:function(ID, action){
      console.log(ID, action);
      if(action){
        this.selectedItem.push(ID)
      }else{
        this.selectAll = false;
        var index = this.selectedItem.indexOf(ID)
        this.selectedItem.splice(index, 1);
      }
      console.log(this.selectedItem.length);
      this.selectedItemCounter = this.selectedItem.length

    },
    updateEventStatus: async function(){
      this.isLoading = true;
      var object = {
        "EventIDList": this.selectedItem,
        "Status": this.newEventStatus.Key
      }
      console.log("updateEventStatus", object)
      try{
        const ret= await this.$store.dispatch('updateEventStatus',{router:this.router,data:object})
        console.log("updateEventStatus", ret)
        await this.delayFunction();
        this.isLoading = false;
        this.showUpdateStatusPopup = false;
        this.message = this.selectedItem.length + " 個活動狀態已更新"
        this.showMessage = true;
        this.searchItem({keyword:""});
      }catch(err){
        this.isLoading = false;
        console.log(err)
      }
    },
    getEventType: async function (){
        try{
            const ret = await this.$store.dispatch('getEventType',{router:this.router})
            console.log("getEventType", ret);
            var EventType = ret.data.EventType
            var EventTypeFilter = this.filterOption.find(option => option.Key == "EventType")
            EventTypeFilter.option = [];
            for(var i in EventType){
                EventTypeFilter.option.push({
                    "Key": EventType[i].EventTypeID,
                    "Name": EventType[i].Name.en+" / "+EventType[i].Name.zh,
                    "Value": false
                })
            }
        }catch(err){
            console.log("getEventType error",  err);
            this.formMessage = err.response.data.message
            this.showFormMessage = true
        }
    },
    getEventLevel: async function (){
        try{
            const ret = await this.$store.dispatch('getEventLevel',{router:this.router})
            console.log("getEventLevel", ret);
            var EventLevel = ret.data.EventLevel
            var EventLevelFilter = this.filterOption.find(option => option.Key == "EventLevel")
            EventLevelFilter.option = [];
            for(var i in EventLevel){
              EventLevelFilter.option.push({
                    "Key": EventLevel[i].EventLevelID,
                    "Name": EventLevel[i].Name.en+" / "+EventLevel[i].Name.zh,
                    "Value": false
                })
            }
        }catch(err){
            console.log("getEventLevel error",  err);
            this.formMessage = err.response.data.message
            this.showFormMessage = true
        }
    },
    setFilter: function (filterOption){
      console.log("setFilter", filterOption);
        this.filter = {};
      for(var f in filterOption){
        var option = filterOption[f]
        if(option.type == "range"){
          var filterObject = {}
          if(option.from){
            filterObject["Start"] = option.from
          }
          if(option.to){
            filterObject["End"] = option.to
          }
          if(filterObject.Start || filterObject.End){
            this.range[option.Key] = filterObject
          }
        }else{
          var filterList = [];
          for(var o in option.option){
            if(option.option[o].Value){
              filterList.push(option.option[o].Key)
            }
          } 
          if(filterList.length > 0){
            this.filter[option.Key] = filterList
          }
        }
      } 
        this.selectAll = false;
        this.selectedItem = [],
        this.selectedItemCounter = 0;

        this.index = 0;
        this.pageNumber = 1;
        console.log("filter", this.filter)
        this.searchItem({keyword:""});
      },
    searchItem: async function({keyword}){
      
      this.isLoading = true;
      var object = {
        "Size": this.displaySize,
        "From": this.index,
        "Sort": this.Sort,
        "Source": this.Source,
        "Filter": this.filter,
        "Range": this.range
      }
      if(keyword){
        object["Keyword"] = keyword
        this.selectAll = false;
        this.selectedItem = [],
        this.selectedItemCounter = 0;
        this.index = 0;
        this.pageNumber = 1;
      }
      console.log(object);
      try{
        const ret= await this.$store.dispatch('searchEvent',{router:this.router,keyword:object})
        //this.setDisplayList(ret.data.EventList)
        this.setDisplayList(ret.data.data.hits.hits)
        this.isLoading = false;
      }catch(err){
        this.isLoading = false;
        console.log("searchEvent error", err.response.data);
        this.formMessage = err.response.data.message
        this.showFormMessage = true
      }
    },

      setDisplayList(list){
        console.log("setDisplayList", list)
        this.TableItemList = [];
        for(var i = 0; i<list.length; i++){
          this.TableItemList.push({
            "ID": list[i]["_source"].EventID,
            "Event Name": list[i]["_source"].Title,
            "Type": list[i]["_source"].EventType.Name.zh,
            "Level": list[i]["_source"].EventLevel.Name.zh,
            "Status": list[i]["_source"].Status,
          })
        }
        this.TableSelectUIControl(this.TableItemList)
      },
      TableSelectUIControl(ItemList){
        this.showNextButton = true;
        if(ItemList.length < this.displaySize){
          this.showNextButton = false;
        }
        var counter = 0;
        for(var i=0; i<ItemList.length;i++){
            var index = this.selectedItem.indexOf(ItemList[i].ID);
            if(index > -1){
                counter++
            }
        }
        if(counter ==  ItemList.length){
            this.selectAll = true;
        }else{
            this.selectAll = false;
        }
      }
  }
};
</script>
