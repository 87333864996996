import oneSipApiCall from "../apiCall.js"
import axios from "axios"
import url from "../config.js"
const customer={

  SearchUser({commit,state},{router,keyword}){
    return oneSipApiCall(commit,state,router,'/AdminSearchUser',keyword)
  },
  SearchUserByEmail({commit,state},{router,keyword}){
    return oneSipApiCall(commit,state,router,'/AdminSearchUserByEmail',keyword)
  },
  createCustomer({commit,state},{router,customer}){
    return oneSipApiCall(commit,state,router,'/AdminCreateNewUser',customer)
  },
  updateUserType({commit,state},{router,customer}){
    return oneSipApiCall(commit,state,router,'/AdminUpdateUserType',customer)
  },
  updateCustomer({commit,state},{router,customer}){
    return oneSipApiCall(commit,state,router,'/AdminUpdateUserInfo',customer)
  }, 

  getCustomerInfo({commit,state},{router,customer}){
    return oneSipApiCall(commit,state,router,'/AdminGetUserInfo',customer)
  },
  getCustomerOrder({commit,state},{router,customer}){
    return oneSipApiCall(commit,state,router,'/AdminGetUserOrder',customer)
  },
  getCustomerGathering({commit,state},{router,customer}){
    return oneSipApiCall(commit,state,router,'/AdminGetUserGathering',customer)
  },
  getCustomerUserGroup({commit,state},{router,customer}){
    return oneSipApiCall(commit,state,router,'/AdminGetUserUserGroup',customer)
  },
  getUserList({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/AdminGetUserList',data)
  },
  enabledisableCustomer({commit,state},{router,data}){
    return oneSipApiCall(commit,state,router,'/AdminEnableDisableUser',data)
  },
  listAllUserID({commit,state},{router}){
    return oneSipApiCall(commit,state,router,'/AdminListAllUserID')
  },

  addToUserGroup({commit,state}, Customer){
    return new Promise((resolve, reject) => {
      commit('action_request');
      //resolve(state.idToken);
      axios({url: url+'/add-user-to-user-group', data:Customer, method: 'POST' })
      .then(resp => {
        commit('action_success');
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        if(err.response.stats==401){
          
        }
        commit('action_error',{err})
        reject(err)
      })
      
    })
  }

}

export default customer;