<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
      <md-card>
        <md-card-content>
          <button @click="payment('CreditCard')" class="tablenewItemButton">CreditCard</button>
          <button @click="payment('Alipay')" class="tablenewItemButton" style="margin-left: 10px;">Alipay</button>
          <button @click="payment('WeChat')" class="tablenewItemButton" style="margin-left: 10px;">WeChat</button>
          <button @click="payment('Wallets')" class="tablenewItemButton" style="margin-left: 10px;">Wallets</button>
        </md-card-content>
      </md-card>
      </div>
    
    </div>
    <Spinner v-if="isLoading"/> 
    <md-snackbar :md-active.sync="showMessage">{{message}}</md-snackbar>
    
  </div>
</template>

<script>
import { 
  Spinner,
 } from "@/components";

export default {
  components: {
    Spinner,
    //StripeElementPayment
  },
  data() {
    return {
      isLoading: false,
      showMessage: false,
      message: "",
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    payment: async function (paymentType) {
      this.isLoading = true;
      try{
        const ret= await this.$store.dispatch("testPayment",{router:this.router, data: {PaymentMethod: paymentType}});
        this.isLoading = false;
        console.log("payment", ret);
       
        if(paymentType == "Wallets"){
          this.$router.push({name: 'Wallets', 
          params:{
            paymentID:ret.data.paymentResult.PaymentID, 
            clientSecret:ret.data.paymentResult.clientSecret, 
            total:ret.data.paymentResult.total}
          }) 
        }else{
          this.$router.push({name: 'Stripe', params:{clientSecret:ret.data.paymentResult.clientSecret}})
        } 
      }
      catch(err){
        console.log("testPayment error", err.response.data);
        this.formMessage = err.response.data.message
        this.showFormMessage = true
      }
    },
  }
}
</script>
<style>

</style>