
  <template>
    <div class="content">
      <div class="md-layout">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
        <md-card>
          <md-card-content>
            <div id="dropin-container"></div>
            <div id="payment-request-button">
                <!-- A Stripe Element will be inserted here. -->
            </div>
          </md-card-content>
        </md-card>
        </div>
      
      </div>
      <Spinner v-if="isLoading"/> 
      <md-snackbar :md-active.sync="showMessage">{{message}}</md-snackbar>
      
    </div>
  </template>
  <script>

  let elements;
  export default {
    head: {
      script: [
        { src: 'https://js.stripe.com/v3' },
      ],
    },
    components: {
      //StripeElementPayment
    },
    data() {
      return {
        stripe: null,
        isLoading: false,
        showMessage: false,
        showTab: "",
        message: "",
        clientSecret: "",
        paymentID: "",
        total: ""
      }
    },
    created() {
        this.paymentID = this.$route.params.paymentID;
        this.clientSecret = this.$route.params.clientSecret;
        this.total = this.$route.params.total;
        console.log("this.paymentID", this.paymentID);
        console.log("this.clientSecret", this.clientSecret);
        console.log("this.total", this.total);
        this.generatePaymentIntent();
    },
    mounted() {
    },
    methods: {
        async generatePaymentIntent() {
            this.stripe = Stripe( process.env.VUE_APP_STRIPE_PUBLIC_KEY );
            const paymentRequest = this.stripe.paymentRequest({
                    country: "HK",
                    currency: "hkd",
                    total: {
                    label: "Wesip",
                    amount: Number(this.total)*100,
                    // amount: this.total,
                },
                googlePay: true,
            });
            elements = this.stripe.elements();
            const prButton = elements.create("paymentRequestButton", {
                paymentRequest,
            });
            (async () => {
                // Check the availability of the Payment Request API first.
                const result = await paymentRequest.canMakePayment();
                console.log("result", result);
                if (result) {
                prButton.mount("#payment-request-button");
                } else {
                document.getElementById("payment-request-button").style.display =
                    "none";
                }
            })();

            const url = process.env.VUE_APP_API_URL+"test/stripe-callback/creditcard-payment-confirm?payment_intent=" +
            this.paymentID +
            "&payment_intent_client_secret=" +
            this.clientSecret +
            "&redirect_status=succeeded";
            paymentRequest.on("paymentmethod", async (ev) => {
                console.log("ev", ev);
                // Confirm the PaymentIntent without handling potential next actions (yet).
                const { paymentIntent, error: confirmError } =
                await this.stripe.confirmCardPayment(
                    this.clientSecret,
                    { payment_method: ev.paymentMethod.id },
                    { handleActions: true },
                    { return_url: url }
                );
                console.log("paymentIntent", paymentIntent);

                if (confirmError) {
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                ev.complete("fail");
                } else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                ev.complete("success");
                // Check if the PaymentIntent requires any actions and if so let Stripe.js
                // handle the flow. If using an API version older than "2019-02-11"
                // instead check for: `paymentIntent.status === "requires_source_action"`.
                if (paymentIntent.status === "requires_action") {
                    // Let Stripe.js handle the rest of the payment flow.

                    const { error } = await this.stripe.confirmCardPayment(
                    this.clientSecret,
                    { return_url: url }
                    );
                    if (error) {
                    // The payment failed -- ask your customer for a new payment method.
                    } else {
                    console.log("payment succeeded 1");
                    console.log("url 1", url);
                    window.location.href = url;
                    // window.open(url);
                    // The payment has succeeded.
                    }
                } else {
                    console.log("url 2", url);
                    console.log("payment succeeded 2");
                    window.location.href = url;
                    // window.open(url);
                    // The payment has succeeded.
                }
                }
            });
        } 
    //   async pay() {
    //     const { error } = await this.stripe.confirmPayment({
    //       elements,
    //       confirmParams: {
    //         return_url: process.env.VUE_APP_API_URL+"test/stripe-callback",
    //       },
    //     });
    //     console.log(error);
    //     //"https://75mcauivra.execute-api.ap-southeast-1.amazonaws.com/test/stripe-callback",
    //   },
    }
  }
  </script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.hidden {
  display: none;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
</style>