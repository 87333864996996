import { render, staticRenderFns } from "./UpdateSmallPromotionBanner.vue?vue&type=template&id=c49338ce&"
import script from "./UpdateSmallPromotionBanner.vue?vue&type=script&lang=js&"
export * from "./UpdateSmallPromotionBanner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports